import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { type PastTopicType, PastTopicsDocument } from 'frontend/api/generated';
import { Checkbox, Input, Loader } from 'frontend/components';
import { useBotOrSkill, useLanguages } from 'frontend/hooks';

import styles from './TopicsFilter.scss';
import { FilterContext } from '../../context/FilterContext';

type TopicsList = (Pick<PastTopicType, 'id' | 'name'> & { key?: string })[];

type Props = { filterKey: string; shouldSubmitOnToggle: boolean };

function TopicsFilter({ filterKey, shouldSubmitOnToggle }: Props) {
  const [searchTopics, setSearchTopics] = useState<TopicsList>([]);
  const { change, submit } = useForm();
  const buildIdObject = useBotOrSkill();
  const { languages } = useLanguages(buildIdObject);

  const { steps } = useContext(FilterContext);
  const { values } = steps?.[filterKey] || {};
  /* This is so when you load the page directly, without taking the steps, we show all data from all steps */
  const [selectedLanguages] = values || [languages.map(({ code }) => code)];

  const {
    input: { value },
  } = useField(filterKey);

  const { botId } = useParams();

  const { data: topicsToSearch, loading } = useQuery(PastTopicsDocument, {
    variables: {
      botId: botId!,
      languageCodes: selectedLanguages,
    },
    onCompleted: ({ pastTopics }) => {
      if (pastTopics) {
        const topicsList: TopicsList = [];
        let apiTopics = [...pastTopics];
        if (value?.length) {
          apiTopics = apiTopics.filter(({ topics }) => topics.some(({ id }) => value.includes(id)));
        }

        apiTopics.forEach(({ language, topics }) => {
          topicsList.push(
            {
              id: language.code,
              key: 'language',
              name: language.name,
            },
            ...(topics || []),
          );
        });

        setSearchTopics(topicsList);
      }
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.topicsSearch}>
        <Input
          className={styles.topicsSearchInput}
          input={{
            name: 'search-topics',
            onChange: (e) => {
              if (topicsToSearch?.pastTopics) {
                const topicsList: TopicsList = [];

                topicsToSearch.pastTopics.forEach(({ language, topics }) => {
                  const filteredTopics: { id: string; name: string; key?: string }[] = [];
                  topics?.forEach(({ id, name }) => {
                    if (name?.toLowerCase().includes(e.target.value?.toLowerCase())) {
                      filteredTopics.push({
                        id,
                        name,
                      });
                    }
                  });

                  if (filteredTopics.length) {
                    topicsList.push(
                      {
                        id: language.code,
                        key: 'language',
                        name: language.name,
                      },
                      ...(filteredTopics || []),
                    );
                  }
                });

                setSearchTopics(topicsList);
              }
            },
          }}
          placeholder="Search topics"
        />
      </div>

      {searchTopics.map(({ key, name, id }) => {
        if (key) {
          return (
            <h4 key={id} className={styles.languageHeader}>
              {name}
            </h4>
          );
        }

        return (
          <Checkbox
            allowToggleOnWrapper
            labelClassName={styles.topicLabel}
            key={id}
            title={name}
            input={{
              name: id,
              checked: value.includes(id),
              onChange: () => {},
            }}
            onClick={() => {
              const valueCopy = [...value];
              if (valueCopy.includes(id)) {
                valueCopy.splice(valueCopy.indexOf(id), 1);
              } else {
                valueCopy.push(id);
              }
              change(filterKey, valueCopy);
              if (shouldSubmitOnToggle) {
                submit();
              }
            }}
            label={name || ''}
          />
        );
      })}
    </div>
  );
}

export default TopicsFilter;
