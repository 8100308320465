import cx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';

import SearchConfused from 'frontend/assets/animations/search-confused.riv';
import { Clean } from 'frontend/assets/icons';
import { EmptyState } from 'frontend/components';
import Loader from 'frontend/components/Loader';
import useHasActiveFilters from 'frontend/components/NewFilters/hooks/useHasActiveFilters';
import { useBotLabels, useUrlSearchParams } from 'frontend/hooks';
import { setInsightsSelectedChat } from 'frontend/state/dux/insights';
import { useAppDispatch } from 'frontend/state/hooks';

import styles from './ConversationList.scss';
import useChats from '../../hooks/useChats';
import { MINIMUM_CHARACTERS_BEFORE_SEARCH } from '../../utils/constants';
import ChatPreview from '../ChatPreview/ChatPreview';

interface ConversationListProps {
  clearSearch: () => void;
  onScrolled: (boolean) => void;
  debouncedSearch: (e: React.SyntheticEvent | undefined, search: string) => void;
}

const ConversationList = ({ clearSearch, onScrolled, debouncedSearch }: ConversationListProps) => {
  const { search, pathname } = useLocation();
  const [params] = useUrlSearchParams();
  const { chatId } = useParams();
  const dispatch = useAppDispatch();
  const botLabels = useBotLabels();
  const areChatsLoaded = useRef(false);

  const [hasScrolled, setHasScrolled] = useState(false);

  const { chats, areChatsLoading, hasMoreChats, total, handleVisibilitySensorChange } = useChats();

  useEffect(() => {
    // This is so if on initial load the chat id doesn't exist in the list
    // we fetch that chat, we set the dates then we show that chat within that time frame
    if (chatId && chats && chats.length > 0 && !chats.some(({ id }) => id === chatId) && !areChatsLoaded.current) {
      debouncedSearch(undefined, chatId);
    }

    if (chats.length) {
      areChatsLoaded.current = true;
    }
  }, [chatId, debouncedSearch, chats]);

  const navigate = useNavigate();
  const hasActiveFilters = useHasActiveFilters();

  // We handle shadows both on search container and on chats length container
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !hasScrolled) {
      setHasScrolled(true);
      if (!search) {
        onScrolled(true);
      }
    } else if (e.target.scrollTop === 0) {
      setHasScrolled(false);
      onScrolled(false);
    }
  };

  const handleOnClick = useCallback(
    (selectedChatId) => dispatch(setInsightsSelectedChat(chats.find(({ id }) => id === selectedChatId))),
    [chats, dispatch],
  );

  const renderChats = () => (
    <>
      {chats.length === 0 && (
        <EmptyState
          className={styles.emptyStateWrapper}
          title={search ? 'No match found' : 'Nothing to show'}
          riveSrc={SearchConfused}
          rotateRiveSources
          iconWidth={100}
          riveProperties={{
            autoLoop: true,
          }}
          {...(search && {
            actionButtonProps: {
              text: 'Clear',
              color: 'white',
              icon: Clean,
              onClick: () => {
                clearSearch();
                navigate(pathname);
              },
            },
          })}
          color="pink"
          description={
            search
              ? 'No match found for this search or filter. Try changing your search or filter input. You can also clear your input.'
              : 'There are no chats to be shown.'
          }
        />
      )}
      {chats.map((chat) => (
        <ChatPreview
          key={`chat-preview-${chat.id}`}
          onClick={handleOnClick}
          id={chat.id}
          updated={chat.updated}
          avatarUrl={chat.avatarUrl}
          autoUser={chat.autoUser}
          feedbacks={chat.feedbacks}
          fullName={chat.fullName}
          subject={chat.subject}
          topicName={chat.topicName}
          takenOver={chat.takenOver}
          completedTakeover={chat.completedTakeover}
          contactDetails={chat.contactDetails}
          lastMessageIsFromUser={chat.lastMessageIsFromUser}
          to={{ pathname: `/workspace/${chat.botId}/insights/chat/${chat.id}`, search }}
          preview={chat?.preview || ''}
          labelIds={chat.labelIds}
          selectedChatId={chatId}
          search={params.search}
          botLabels={botLabels}
        />
      ))}

      {hasMoreChats && (
        <VisibilitySensor onChange={handleVisibilitySensorChange}>
          {({ isVisible }) => (
            <div className="m-t-lg m-b-lg text-center">
              <Loader animate={isVisible} />
            </div>
          )}
        </VisibilitySensor>
      )}
    </>
  );

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.chatsLengthWrapper, {
          [styles.chatScrolled]: hasScrolled && search,
        })}
      >
        {(params?.search?.length >= MINIMUM_CHARACTERS_BEFORE_SEARCH || hasActiveFilters) && (
          <div className={styles.chatsLength}>
            Showing {chats.length} out of {total}
          </div>
        )}
      </div>
      <div
        className={cx(styles.chatsContent, {
          [styles.emptyContainer]: chats.length === 0,
        })}
        onScroll={handleScroll}
        data-testid="insights-conversation-list"
      >
        {areChatsLoading ? <Loader size="large" type="inbox_chats" /> : renderChats()}
      </div>
    </div>
  );
};

export default ConversationList;
