import { useQuery } from '@apollo/client';
import { LuzmoDashboardComponent, type LuzmoEmbedDashboard } from '@luzmo/react-embed';
import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  BotMetaDocument,
  type EmbeddedAnalyticsFilterType,
  LuzmoTokenBotDocument,
  LuzmoTokenEngagementDocument,
  LuzmoTokenOrganizationDocument,
} from 'frontend/api/generated';
import { Panel } from 'frontend/components';
import Filters from 'frontend/components/NewFilters/Filters';
import { convertQueryParamsToPersistedValues } from 'frontend/components/NewFilters/utils/helpers';
import { shouldShowComments } from 'frontend/features/Comments/utils/helpers';
import { convertFormValuesToQueryParams } from 'frontend/features/Inbox/components/InboxFilters/utils/helpers';
import { selectComments } from 'frontend/state/dux/comments';
import { useAppSelector } from 'frontend/state/hooks';
import { CHAT_SOURCE } from 'frontend/types/chat';
import { DATE_FORMAT_ISO_DATE, SEVEN_DAYS_AGO, TODAY_DATE } from 'frontend/utils/date';

import styles from './EmbeddedAnalytics.scss';
import { LUZMO_DASHBOARDS } from './constants';

export const initialAnalyticsFilter = {
  dateFilter: [`${format(SEVEN_DAYS_AGO, DATE_FORMAT_ISO_DATE)}|${format(TODAY_DATE, DATE_FORMAT_ISO_DATE)}`],
};

const initialStateFilters = { sources: Object.values(CHAT_SOURCE).filter((source) => source !== CHAT_SOURCE.TEST) };

interface EmbeddedAnalyticsProps {
  integrationLevel: 'bot' | 'organization' | 'platform' | 'engagement';
  /** Dashboard slug to have a unique string for the dashboard (that doesn't change if we switch dashboards under the hood). It doesn't come from Luzmo. */
  dashboardSlug: string;
  /** Dashboard ID from Luzmo. */
  dashboardId: string;
  shouldDisplayFilters?: boolean;
  customEvent?: (event: any) => void;
}

function getAnalyticsFilters(param: URLSearchParams): EmbeddedAnalyticsFilterType {
  const filters = { ...initialStateFilters } as EmbeddedAnalyticsFilterType;
  if (param) {
    param.forEach((value, key) => {
      if (key === 'dateFilter') {
        const [startDateString, endDateString] = value.split('|');
        if (startDateString && endDateString) {
          filters.dateRange = { startDate: startDateString, endDate: endDateString };
        }
      } else if (key === 'languageFilter') {
        filters.languages = value.split(',');
      } else if (key === 'sourceFilter') {
        filters.sources = value.split(',');
      }

      if (!filters.languages) {
        filters.languages = [];
      }
    });
  }
  return filters;
}

export default function EmbeddedAnalytics({
  integrationLevel,
  dashboardId,
  dashboardSlug,
  shouldDisplayFilters = true,
  customEvent,
}: EmbeddedAnalyticsProps): React.JSX.Element | null {
  const [luzmoSecrets, setLuzmoSecrets] = useState({ id: '', token: '' });
  const { botId, organizationId } = useParams();
  const [URLQueryParams] = useSearchParams();
  const navigate = useNavigate();

  const { open } = useAppSelector(selectComments);
  const showComments = shouldShowComments(window.location.pathname);

  const filters = getAnalyticsFilters(URLQueryParams);

  const isEngagementDashboard = integrationLevel === 'engagement';

  const { data: dataBotMeta, loading: loadingBotMeta } = useQuery(BotMetaDocument, { variables: { id: botId! } });
  const engagementId = dataBotMeta?.bot?.engagementId;
  const timezoneId = dataBotMeta?.bot?.timezone;
  const noEngagementVars = isEngagementDashboard && !(engagementId && botId);

  const dashboardRef = useRef<LuzmoEmbedDashboard>(null);

  const isLuzmoSecretsEmpty = !luzmoSecrets.id || !luzmoSecrets.token;

  const luzmoQuery = {
    bot: LuzmoTokenBotDocument,
    organization: LuzmoTokenOrganizationDocument,
    engagement: LuzmoTokenEngagementDocument,
  }[integrationLevel];

  const integrationVariables = {
    bot: { botId: botId! },
    organization: { organizationId: organizationId! },
    engagement: { botId: botId! },
  }[integrationLevel];

  const dashboardVariables = {
    [LUZMO_DASHBOARDS.AUTHENTICATED.slug]: { filters },
    [LUZMO_DASHBOARDS.BEHAVIOR.slug]: { filters },
    [LUZMO_DASHBOARDS.BOT_DASHBOARD.slug]: { filters },
    [LUZMO_DASHBOARDS.BOT_FEEDBACK.slug]: { filters },
    [LUZMO_DASHBOARDS.CONTAINMENT_RATE.slug]: { filters },
    [LUZMO_DASHBOARDS.HANDOVER_FEEDBACK.slug]: { filters },
    [LUZMO_DASHBOARDS.KINDLY_GPT.slug]: { filters },
    [LUZMO_DASHBOARDS.LABELS.slug]: { filters },
    [LUZMO_DASHBOARDS.TOPICS.slug]: { filters },
    [LUZMO_DASHBOARDS.TOPICS_V2.slug]: { filters },
    [LUZMO_DASHBOARDS.USAGE.slug]: { filters },
    [LUZMO_DASHBOARDS.ESTIMATED_COSTS.slug]: {},
    [LUZMO_DASHBOARDS.EMAIL_DETAILS.slug]: {},
    [LUZMO_DASHBOARDS.EMAIL_OVERVIEW.slug]: {},
    [LUZMO_DASHBOARDS.NUDGE_DETAILS.slug]: {},
    [LUZMO_DASHBOARDS.NUDGE_OVERVIEW.slug]: {},
  }[dashboardSlug];

  const shouldSkipQuery = { bot: !botId, organization: !organizationId, engagement: noEngagementVars }[
    integrationLevel
  ];

  useQuery(luzmoQuery, {
    skip: shouldSkipQuery,
    variables: { ...integrationVariables, ...dashboardVariables },
    onCompleted: (dataOnCompleted) => {
      const luzmo = {
        bot: dataOnCompleted.embeddedBotDashboard,
        organization: dataOnCompleted.embeddedOrgDashboard,
        engagement: dataOnCompleted.embeddedEngagementDashboard,
      }[integrationLevel].auth;

      if (isLuzmoSecretsEmpty) {
        // When the dashboard is to be initialized, we need to set the authorization on the React component
        setLuzmoSecrets({ id: luzmo.id, token: luzmo.token });
      } else {
        // When the dashboard is already initialized, we use the component methods to update the dashboard outside React (so that the whole dashboard doesn't get loaded again)
        dashboardRef.current?.setAuthorization(luzmo.id, luzmo.token).then(() => dashboardRef.current?.refreshData());
      }
    },
  });

  if (isEngagementDashboard && loadingBotMeta) {
    return null;
  }
  if (noEngagementVars) {
    return (
      <Panel centered>
        <h2>
          <strong>Engagement ID</strong> is not set up for this bot.
        </h2>
        <p>Get in touch with your contact person in Kindly or send us a message to support@kindly.ai.</p>
      </Panel>
    );
  }

  if (isLuzmoSecretsEmpty) {
    return null;
  }

  return (
    <div>
      {shouldDisplayFilters && (
        <div className={styles.embeddedAnalyticsFiltersWrapper}>
          <Filters
            path="ANALYTICS"
            filters={['dateFilter', 'sourceFilter', 'languageFilter']}
            pinnedFilters={['dateFilter']}
            canIncludeExclude={false}
            canAddMoreThanOne={false}
            submitOn="toggle"
            clearButtonTitle="Reset"
            onClear={(_, reset) => {
              navigate(`?${convertFormValuesToQueryParams(initialAnalyticsFilter)}`);
              const initialValues = convertQueryParamsToPersistedValues();
              reset(initialValues);
            }}
          />
        </div>
      )}
      <div className={styles.dashboardWrapper}>
        <LuzmoDashboardComponent
          authKey={luzmoSecrets.id}
          authToken={luzmoSecrets.token}
          style={{ width: showComments && open ? `60vw` : '100%' }}
          dashboardId={dashboardId}
          loaderSpinnerColor="rgb(0, 105, 255)"
          loaderSpinnerBackground="rgb(28, 211, 0)"
          ref={dashboardRef}
          timezoneId={timezoneId}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(customEvent && { customEvent })}
        />
      </div>
      <p className={styles.helpText}>
        The timezone used for analytics is the <b>workspace timezone</b>, which is currently set to <b>{timezoneId}</b>.
      </p>
    </div>
  );
}
