import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';

import { BotSubscriptionsDocument } from 'frontend/api/generated';
import LibraryFolder from 'frontend/features/Library/components/LibraryFolder/LibraryFolder';
import { useToggle } from 'frontend/hooks';
import type { BuildIdObjectBot } from 'frontend/propTypes/BuildIdObjectType';

import { useBreadcrumbs, useIndentationStyle } from '../../hooks';
import { getFolderIconClassnames, isSubscriptionPath } from '../../utils';
import Subscription from '../Subscription';

const SubscriptionFolder = ({ buildIdObject }: { buildIdObject: BuildIdObjectBot }) => {
  const [open, toggleOpen] = useToggle();
  const { data } = useQuery(BotSubscriptionsDocument, { variables: buildIdObject });

  const subscriptions = data?.bot?.subscriptions ?? [];

  const { isInBreadcrumbs } = useBreadcrumbs({
    open,
    toggleOpen,
    checkBreadcrumbs: isSubscriptionPath,
    autoOpen: false,
  });
  const indentationStyle = useIndentationStyle(0);

  if (subscriptions.length === 0) {
    return null;
  }

  const active = isInBreadcrumbs && !open;
  const titleClassName = getFolderIconClassnames(open);

  return (
    <>
      <LibraryFolder toggleOpen={toggleOpen} active={active}>
        <span style={indentationStyle} className={titleClassName}>
          Skills
        </span>
      </LibraryFolder>
      {open &&
        orderBy(subscriptions, 'name').map((subscription) => (
          <Subscription
            key={`skill-subscription-${subscription.id}`}
            buildIdObject={buildIdObject}
            subscription={subscription}
            indentationLevel={1}
          />
        ))}
    </>
  );
};

export default SubscriptionFolder;
