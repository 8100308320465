import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DropDialogueInFolderDocument, DropFolderInFolderDocument } from 'frontend/api/generated';
import {
  type DropDialogueItem,
  type DropFolderItem,
  dropDialogue,
  dropFolder,
} from 'frontend/features/Library/components/FolderItem/utils';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

export function useDropInFolder({
  setShowLoader,
  buildIdObject,
  targetFolderId,
  name,
  currentLanguage,
}: {
  setShowLoader: (showLoader: boolean) => void;
  buildIdObject: BuildIdObject;
  targetFolderId: string;
  name: string;
  currentLanguage: string;
}) {
  const [dialogueInFolder] = useMutation(DropDialogueInFolderDocument, REFETCH_LIBRARY);
  const [folderInFolder] = useMutation(DropFolderInFolderDocument, REFETCH_LIBRARY);
  const toast = useToast();

  const drop = useCallback(
    async (item: DropDialogueItem | DropFolderItem) => {
      setShowLoader(true);
      try {
        if (item.type === 'DIALOGUE') {
          await dropDialogue({
            item,
            buildIdObject,
            targetFolderId,
            dialogueInFolder,
            toast,
            name,
            currentLanguage,
          });
        } else if (item.type === 'FOLDER') {
          await dropFolder({
            folderInFolder,
            targetFolderId,
            item,
            buildIdObject,
            toast,
            name,
          });
        }
      } finally {
        setShowLoader(false);
      }
    },
    [setShowLoader, buildIdObject, targetFolderId, dialogueInFolder, toast, currentLanguage, name, folderInFolder],
  );

  return drop;
}
