import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DropDialogueInDialogueDocument } from 'frontend/api/generated';
import { dropDialogue } from 'frontend/features/Library/components/DialogueItem/utils';
import type { DropDialogueItem } from 'frontend/features/Library/components/FolderItem/utils';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { useBotOrSkill, useToast } from 'frontend/hooks';

type Props = {
  id: string;
  setShowLoader: (showLoader: boolean) => void;
  skillId?: string;
  currentTitle: string;
  currentLanguage: string;
};

type Hook = (item: DropDialogueItem) => Promise<void>;

export function useDropInDialogue({ id, setShowLoader, skillId, currentTitle, currentLanguage }: Props): Hook {
  const toast = useToast();
  const { buildIdObject } = useBotOrSkill();

  const [dialogueInDialogue] = useMutation(DropDialogueInDialogueDocument, REFETCH_LIBRARY);

  return useCallback(
    async (item: DropDialogueItem): Promise<void> => {
      try {
        setShowLoader(true);
        if (item.type === 'DIALOGUE') {
          await dropDialogue({
            id,
            toast,
            buildIdObject: buildIdObject!,
            skillId,
            dialogueInDialogue,
            item,
            currentTitle,
            currentLanguage,
          });
        }
      } finally {
        setShowLoader(false);
      }
    },
    [buildIdObject, currentLanguage, currentTitle, dialogueInDialogue, id, setShowLoader, skillId, toast],
  );
}
