import { useState } from 'react';
import { useDrop } from 'react-dnd';

import { DIALOGUE_TYPES } from 'frontend/constants';
import { useDialogueFolderContextMenu } from 'frontend/features/Library/components/DialogueFolder/useDialogueFolderContextMenu';
import { useDropInFolder } from 'frontend/features/Library/components/DialogueFolder/useDropInFolder';
import type { DropDialogueItem, DropFolderItem } from 'frontend/features/Library/components/FolderItem/utils';
import { isRegularDialoguePath } from 'frontend/features/Library/utils/breadcrumbs';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { getFolderClassnames } from '../../utils';
import BottomFolder from '../BottomFolder';

function canDrop(item: DropDialogueItem | DropFolderItem): boolean {
  if (item.type === 'FOLDER') {
    return Boolean(item.parentFolderId);
  }

  if (item.type === 'DIALOGUE') {
    return Boolean(item.topicId || item.parentId || item.dialogueModParentId);
  }

  return false;
}

export default function DialogueFolder({ buildIdObject }: { buildIdObject: BuildIdObject }) {
  const { actions, contextMenuId } = useDialogueFolderContextMenu();
  const [showLoader, setShowLoader] = useState(false);
  const { drop } = useDropInFolder({ buildIdObject, setShowLoader });

  const [dropProps, dropRef] = useDrop({
    accept: ['DIALOGUE', 'FOLDER'],
    collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
    canDrop,
    drop,
  });

  return (
    <BottomFolder
      buildIdObject={buildIdObject}
      actions={actions}
      contextMenuId={contextMenuId}
      showLoader={showLoader}
      name="Dialogues"
      ref={dropRef}
      checkBreadcrumbs={isRegularDialoguePath}
      className={getFolderClassnames(dropProps)}
      dialogueType={DIALOGUE_TYPES.REGULAR}
      setShowLoader={setShowLoader}
      indentationLevel={0}
    />
  );
}
