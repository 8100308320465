import { useCallback } from 'react';

import { loginWithSSO, setLoginError } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

interface OAuthResponse {
  error?: string;
  idToken?: string;
  credential?: string;
}

type OnOAuthResponse = (oauthResponse: OAuthResponse) => Promise<void>;

const useSSOLogin = (provider: 'google' | 'azure'): OnOAuthResponse => {
  const dispatch = useAppDispatch();

  const onOAuthResponse: OnOAuthResponse = useCallback(
    async (oauthResponse) => {
      const onOAuthError = (err: string) => dispatch(setLoginError(err));

      const { error, idToken, credential } = oauthResponse;
      if (error) {
        if (window.env?.NODE_ENV === 'development' && error !== 'idpiframe_initialization_failed') {
          onOAuthError(`Login did not complete: ${error}`);
        }
        return;
      }

      if (!idToken && !credential) {
        onOAuthError('Login did not complete: Missing idToken or credential.');
        return;
      }

      const token = idToken || credential;

      dispatch(loginWithSSO(provider, token));
    },
    [dispatch, provider],
  );

  return onOAuthResponse;
};

export default useSSOLogin;
