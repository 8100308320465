import { defineAction } from 'redux-define';

const INSIGHTS = defineAction('insights');

export const SET_INSIGHTS_SEARCH_UI_FILTERS = INSIGHTS.defineAction('SET_INSIGHTS_SEARCH_UI_FILTERS');
export const SET_INSIGHTS_CHAT_DETAILS_OPEN = INSIGHTS.defineAction('SET_INSIGHTS_CHAT_DETAILS_OPEN');
export const SET_INSIGHTS_SELECTED_CHAT = INSIGHTS.defineAction('SET_INSIGHTS_SELECTED_CHAT');
export const SET_INSIGHTS_CHATS_LOADING = INSIGHTS.defineAction('SET_INSIGHTS_CHATS_LOADING');
export const SET_INSIGHTS_PANEL_MENU_MINIMIZED = INSIGHTS.defineAction('SET_INSIGHTS_PANEL_MENU_MINIMIZED');
export const SET_INSIGHTS_CONVERSATIONS_SORT = INSIGHTS.defineAction('SET_INSIGHTS_CONVERSATIONS_SORT');

export const setInsightsChatDetailsOpen = (state) => ({
  type: SET_INSIGHTS_CHAT_DETAILS_OPEN.ACTION,
  data: state,
});

export const setInsightsSearchUIFilters = (filters) => ({
  type: SET_INSIGHTS_SEARCH_UI_FILTERS.ACTION,
  data: filters,
});

export const setInsightsSelectedChat = (selectedChat) => ({
  type: SET_INSIGHTS_SELECTED_CHAT.ACTION,
  data: selectedChat,
});

export const setInsightsChatsLoading = (loading) => ({
  type: SET_INSIGHTS_CHATS_LOADING.ACTION,
  data: loading,
});

export const setInsightsPanelMenuMinimized = (minimized) => ({
  type: SET_INSIGHTS_PANEL_MENU_MINIMIZED.ACTION,
  data: minimized,
});

export const setInsightsConversationsSort = () => ({
  type: SET_INSIGHTS_CONVERSATIONS_SORT.ACTION,
});

export const initialState = {
  selectedChat: null,
  searchUIFilters: {
    showLabels: true,
    assigned: true,
    feedback: true,
    userName: true,
    topicName: true,
  },
  isMenuPanelMinimized: false,
  chatsLoading: false,
  chatDetailsOpen: true,
  sort: 'DESC',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INSIGHTS_SEARCH_UI_FILTERS.ACTION: {
      return { ...state, searchUIFilters: { ...state.searchUIFilters, ...action.data } };
    }
    case SET_INSIGHTS_CHAT_DETAILS_OPEN.ACTION: {
      return { ...state, chatDetailsOpen: action.data };
    }
    case SET_INSIGHTS_SELECTED_CHAT.ACTION:
      return { ...state, selectedChat: action.data };
    case SET_INSIGHTS_CHATS_LOADING.ACTION:
      return { ...state, chatsLoading: action.data };
    case SET_INSIGHTS_PANEL_MENU_MINIMIZED.ACTION:
      return { ...state, isMenuPanelMinimized: action.data };
    case SET_INSIGHTS_CONVERSATIONS_SORT.ACTION:
      return { ...state, sort: state.sort === 'ASC' ? 'DESC' : 'ASC' };
    default:
      return state;
  }
};
