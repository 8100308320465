import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { Checkbox } from 'frontend/components';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { DeleteModal } from 'frontend/features/Modals';

import { RESET_DIALOGUE_MOD } from '../../mutations';

const ResetSkillDialogue = ({ hide, args: { botId, skillId, dialogueId } }) => {
  const [includeFollowUps, setIncludeFollowUps] = useState(false);
  const [mutate] = useMutation(RESET_DIALOGUE_MOD, REFETCH_LIBRARY);

  const onSubmit = useCallback(async () => {
    await mutate({ variables: { botId, skillId, dialogueId, andFollowups: includeFollowUps } });
  }, [botId, dialogueId, includeFollowUps, mutate, skillId]);

  return (
    <DeleteModal onSubmit={onSubmit} hide={hide} action="reset" name="skill dialogue">
      Are you sure you want to reset this dialogue? All changes made to the dialogue will be lost.
      <Checkbox
        className="m-t-2"
        label="Include follow-ups"
        input={{
          name: 'reset-dialogue-follow-ups',
          onChange: () => setIncludeFollowUps(!includeFollowUps),
          checked: includeFollowUps,
        }}
      />
    </DeleteModal>
  );
};

ResetSkillDialogue.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    botId: PropTypes.string.isRequired,
    skillId: PropTypes.string.isRequired,
    dialogueId: PropTypes.string.isRequired,
  }).isRequired,
};

export default ResetSkillDialogue;
