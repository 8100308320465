import { useMutation } from '@apollo/client';
import { mapValues, pick } from 'lodash';
import { useCallback } from 'react';

import { UpdateDialogueMutation } from '../graphql';
import {
  dialogueInputFields,
  getBuildItemUpdate,
  getButtonsUpdate,
  getImageCarouselsUpdate,
  getTitleUpdate,
  handleUrlTrigger,
} from '../utils';
import getFormUpdate from '../utils/getFormUpdate';
import getRulesUpdate from '../utils/getRulesUpdate';
import { prepareDialogueBuildItemForBE } from '../utils/prepareDialogueBuildItem';

export default function useUpdateSpecialDialogue({ botId, dialogueId, selectedLanguage }) {
  const [updateSpecialDialogue] = useMutation(UpdateDialogueMutation);

  const update = useCallback(
    async (values, form) => {
      const currentValues = { ...values, videoSources: mapValues(values.videoSources || {}, (url) => url || '') };
      const { initialValues } = form.getState();
      const titleUpdate = getTitleUpdate(initialValues.title, currentValues.title);
      const replyUpdate = getBuildItemUpdate(initialValues, currentValues, 'replies');
      const buttonUpdate = getButtonsUpdate(initialValues, currentValues);
      const imageCarouselsUpdate = getImageCarouselsUpdate(initialValues, currentValues);
      const formUpdate = getFormUpdate(initialValues, values);
      const rulesUpdate = getRulesUpdate({ initialValues, values: currentValues, language: selectedLanguage });
      const context = prepareDialogueBuildItemForBE(values.context);
      const advancedOptions = prepareDialogueBuildItemForBE(values.advancedOptions);
      const eventMessage = prepareDialogueBuildItemForBE(values.eventMessage);

      const rest = pick(currentValues, dialogueInputFields);
      const dialogueInput = {
        context,
        advancedOptions,
        eventMessage,
        ...rest,
        ...handleUrlTrigger(selectedLanguage, pick(currentValues, ['urlTrigger', 'urlTriggerType'])),
        ...titleUpdate,
        ...replyUpdate,
        ...buttonUpdate,
        ...imageCarouselsUpdate,
        ...formUpdate,
        ...rulesUpdate,
      };

      await updateSpecialDialogue({
        variables: {
          botId,
          dialogueId,
          dialogueInput,
          languageCode: selectedLanguage,
        },
      });
    },
    [updateSpecialDialogue, botId, dialogueId, selectedLanguage],
  );

  return update;
}
