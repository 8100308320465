import cx from 'classnames';
import { type HTMLInputTypeAttribute, forwardRef, useCallback, useEffect, useRef } from 'react';
import type { FieldRenderProps } from 'react-final-form';
import TextareaAutosize from 'react-textarea-autosize';

import type { FIELD_COLOR } from 'frontend/constants';

import styles from './Input.scss';
import InputLimit from './InputLimit';
import InputErrorWrapper from '../InputErrorWrapper';
import type { ErrorPosition } from '../InputErrorWrapper/InputErrorWrapper';

const noop = () => null;

type FieldColor = (typeof FIELD_COLOR)[keyof typeof FIELD_COLOR];

type InputSize = 'small' | 'medium';

type AdornmentPosition = 'left' | 'right';

interface InputProps extends FieldRenderProps<string> {
  size?: InputSize;
  inputType?: HTMLInputTypeAttribute;
  onKeyDown?: () => void;
  onKeyPress?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  className: string;
  autoComplete?: string;
  autoFocus?: boolean;
  hidden?: boolean;
  readOnly: boolean;
  adornment?: React.ReactNode;
  adornmentPosition?: AdornmentPosition;
  inputLimit?: number;
  maxLength?: number;
  placeholder: string;
  label: string | React.ReactNode;
  fieldColor: FieldColor;
  'data-testid': string;
  'aria-label': string;
  multiline: boolean;
  disabled: boolean;
  labelClassName?: string;
  forceValidate?: boolean;
  focusOnError?: boolean;
  errorPosition?: ErrorPosition;
  min?: number | string;
  max?: number | string;
  minRows?: number;
  inputClassName?: string;
}

const Input = forwardRef<HTMLTextAreaElement | HTMLInputElement, InputProps>(
  (
    {
      input: { name, value, onChange = noop, defaultValue },
      onKeyDown,
      onKeyPress,
      onBlur,
      onFocus,
      onClick,
      meta,
      inputType = 'text',
      inputLimit,
      className,
      hidden,
      readOnly,
      autoComplete,
      autoFocus,
      size = 'medium',
      adornment,
      adornmentPosition,
      placeholder,
      label,
      maxLength,
      multiline,
      fieldColor = 'white',
      'aria-label': ariaLabel,
      'data-testid': dataTestid,
      disabled = false,
      labelClassName,
      forceValidate,
      focusOnError = true,
      errorPosition,
      min,
      max,
      minRows,
      inputClassName: inputClassNameProp,
    },
    externalRef,
  ) => {
    const ref = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
    const adornmentRef = useRef<HTMLDivElement>(null);
    const hasError = Boolean((meta?.submitFailed || forceValidate) && (meta?.submitError || meta?.error));
    const errorMessage = meta?.submitError || meta?.error || '';

    const focus = useCallback(
      /* FIXME: If externalRef is a function, we are in trouble */
      () => ((externalRef as React.MutableRefObject<HTMLInputElement | null>)?.current ?? ref.current)?.focus?.(),
      [externalRef],
    );

    useEffect(() => {
      if (hasError && errorMessage && focusOnError) focus();
    }, [focus, errorMessage, focusOnError, hasError]);

    const inputClassName = cx(
      styles.formControl,
      {
        [styles.formControlSmall]: size === 'small',
        [styles.formControlMedium]: size === 'medium',
        [styles.formControlReadOnly]: readOnly,
        [styles.formControlHidden]: hidden,
        [styles.formControlError]: hasError,
        [styles.formControlIconPaddingLeft]: adornmentPosition === 'left',
        [styles.formControlMischka]: fieldColor === 'mischka',
      },
      inputClassNameProp,
    );

    const adornmentClassName = cx(styles.inlineAdornment, {
      [styles.adornmentPositionRight]: adornmentPosition === 'right',
      [styles.adornmentPositionLeft]: adornmentPosition === 'left',
    });

    const InputComponent = multiline ? TextareaAutosize : 'input';

    const labelClass = cx(styles.labelWrapper, labelClassName);

    return (
      <>
        {!!label && (
          <div className={labelClass}>
            <label htmlFor={name}>{label}</label>
            {!!inputLimit && !adornment && (
              <InputLimit hasError={hasError} currentInput={value.toString().length} limit={inputLimit} />
            )}
          </div>
        )}
        <InputErrorWrapper
          hasError={hasError}
          className={className}
          errorMessageClassName={styles.errorMessage}
          displayError={errorMessage}
          errorPosition={errorPosition}
        >
          <InputComponent
            id={name}
            name={name}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            /* FIXME: This component can render an <input> or a <textarea>. Can we infer the types? */
            ref={externalRef || ref}
            readOnly={readOnly}
            type={inputType}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            className={inputClassName}
            placeholder={placeholder}
            autoComplete={autoComplete}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            maxLength={maxLength}
            onClick={onClick}
            data-testid={dataTestid}
            aria-label={ariaLabel}
            disabled={disabled}
            min={min}
            max={max}
            {...(minRows && { minRows })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(adornmentRef.current && {
              style: {
                ...(adornmentPosition === 'left' && { paddingLeft: (adornmentRef.current?.clientWidth || 0) + 14 }),
                ...(adornmentPosition === 'right' && { paddingRight: (adornmentRef.current?.clientWidth || 0) + 14 }),
              },
            })}
          />
          {adornment && (
            <div ref={adornmentRef} className={adornmentClassName}>
              {adornment}
            </div>
          )}
        </InputErrorWrapper>
      </>
    );
  },
);
Input.displayName = 'Input';

export default Input;
