import cx from 'classnames';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';

import { AnnotationDots, Archive, DotsVertical, Pin, SquarePlus } from 'frontend/assets/icons';
import { Dropdown, EmptyState, Icon, Loader } from 'frontend/components';
import AdvancedTooltip from 'frontend/components/AdvancedTooltip/AdvancedTooltip';
import useMe from 'frontend/hooks/useMe';

import styles from './CommentsList.scss';
import useCommentsMutation from '../../hooks/useCommentsMutation';
import useCommentsQuery from '../../hooks/useCommentsQuery';
import useCommentsReadMutation from '../../hooks/useCommentsReadMutation';
import { formatDate } from '../../utils/helpers';

export const CommentsList = () => {
  const { data: meData } = useMe();
  const [activeDropdownCommentId, setActiveDropdownCommentId] = useState<string | null>(null);
  const [activeCommentId, setActiveCommentId] = useState<string | null>(null);
  const { updateComment } = useCommentsMutation();
  const { updateCommentRead } = useCommentsReadMutation();

  const navigate = useNavigate();
  const { data, loading, loadMore } = useCommentsQuery();

  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={cx(styles.commentsContent, {
        [styles.commentsContentEmpty]: !data?.commentPage?.comments.length,
      })}
    >
      {!data?.commentPage?.comments.length && (
        <EmptyState
          title="No comments yet"
          description="There is made no comment here yet. You can use filter to display comments made on local or global level"
          icon={AnnotationDots}
          color="yellow"
        />
      )}
      {data?.commentPage?.comments.map((comment) => (
        <div
          tabIndex={0}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setActiveCommentId(comment.id);
              if (comment.author.id !== meData?.me.id && !comment.read) {
                updateCommentRead({ commentId: comment.id, read: !comment.read });
              }
            }
          }}
          key={comment.id}
          className={cx(styles.comment, {
            [styles.activeDropdownComment]: activeDropdownCommentId === comment.id,
            [styles.pinnedComment]: comment.pinned.isPinned,
            [styles.activeComment]: activeCommentId === comment.id,
          })}
          onClick={() => {
            if (comment.author.id !== meData?.me.id && !comment.read) {
              updateCommentRead({ commentId: comment.id, read: !comment.read });
            }
            setActiveCommentId(comment.id);
          }}
        >
          <div className={styles.commentHeading}>
            {!comment.read && comment.author.id !== meData?.me.id && <div className={styles.commentRead} />}
            <div className={styles.commentAvatar}>
              <img src={comment.author.profile.avatarUrl} alt={comment.author.profile.alias} />
            </div>
            <AdvancedTooltip maxWidth="240">
              <div
                className={comment.page.pageDetails.path !== window.location.pathname ? styles.commentPage : undefined}
                role={comment.page.pageDetails.path !== window.location.pathname ? 'link' : undefined}
                tabIndex={comment.page.pageDetails.path !== window.location.pathname ? 0 : undefined}
                onKeyDown={(e) => {
                  if (comment.page.pageDetails.path !== window.location.pathname && e.key === 'Enter') {
                    navigate(`${comment.page.pageDetails.path}`);
                  }
                }}
                onClick={() => {
                  if (comment.page.pageDetails.path !== window.location.pathname) {
                    navigate(`${comment.page.pageDetails.path}`);
                  }
                }}
              >
                {capitalize(comment.page.pageDetails.category)}
              </div>
              <AdvancedTooltip.Body>Go to this location </AdvancedTooltip.Body>
            </AdvancedTooltip>
            <div className={styles.commentDate}>{formatDate(comment.createdAt)}</div>

            {comment.pinned.isPinned && (
              <div className={styles.commentPinned}>
                <Icon component={Pin} color="darkGray" width={16} height={16} />
              </div>
            )}
            <div className={styles.commentActions}>
              <Dropdown
                position="left"
                onClose={() => {
                  setActiveDropdownCommentId(null);
                }}
                onToggle={(isOpen) => {
                  if (isOpen) {
                    setActiveDropdownCommentId(comment.id);
                  }
                }}
                overlay={
                  <Dropdown.MenuOverlay
                    options={[
                      {
                        icon: Pin,
                        text: `${comment.pinned.isPinned ? 'Unpin' : 'Pin'} comment`,
                        onClick: () =>
                          updateComment({
                            id: comment.id,
                            pin: !comment.pinned.isPinned,
                            pageDetails: comment.page.pageDetails,
                          }),
                      },
                      ...(comment.author.id !== meData?.me.id
                        ? [
                            {
                              icon: SquarePlus,
                              text: `Mark as ${comment.read ? 'unread' : 'read'}`,
                              onClick: () => updateCommentRead({ commentId: comment.id, read: !comment.read }),
                            },
                          ]
                        : []),
                      ...(comment.author.id === meData?.me.id
                        ? [
                            {
                              icon: Archive,
                              text: `${comment.isArchived ? 'Unarchive' : 'Archive'} comment`,
                              onClick: () =>
                                updateComment({
                                  id: comment.id,
                                  archive: !comment.isArchived,
                                  pageDetails: comment.page.pageDetails,
                                }),
                            },
                          ]
                        : []),
                    ]}
                  />
                }
              >
                <Icon component={DotsVertical} color="darkGray" hoverColor="primary" />
              </Dropdown>
            </div>
          </div>
          <div className={styles.commentSender}>
            {comment.author.firstName} {comment.author.lastName}
          </div>
          <div className={styles.commentText}>{comment.text}</div>
        </div>
      ))}
      {data?.commentPage?.hasNext && (
        <ReactVisibilitySensor
          scrollCheck
          onChange={(visible) => {
            if (visible) {
              loadMore();
            }
          }}
        >
          {({ isVisible }) => (
            <div className="m-t-lg m-b-lg text-center">
              <Loader animate={isVisible} />
            </div>
          )}
        </ReactVisibilitySensor>
      )}
    </div>
  );
};

export default CommentsList;
