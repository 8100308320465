import { SENDER_TYPES } from './constants';
import type { CompleteChatMessageType } from './types';

export default (data: CompleteChatMessageType) =>
  data.sender === SENDER_TYPES.WEBHOOK &&
  !data.message &&
  !data.buttons.length &&
  !data.imageCarousel.length &&
  !Object.keys(JSON.parse(data.form || '{}')).length &&
  !data.videoSource &&
  !data.attachments.length;
