import { cloneDeep, get, noop, set } from 'lodash';

export default function updateArrayInCache({
  variables,
  pathToArrayInCache,
  query,
  cache,
  arrayUpdate,
  onNotInCache = noop,
}) {
  try {
    const dataInCache = cache.readQuery({ query, variables });
    const currentArray = get(dataInCache, pathToArrayInCache);
    const updatedArray = arrayUpdate(currentArray);
    const updatedData = set(cloneDeep(dataInCache), pathToArrayInCache, updatedArray);

    cache.writeQuery({ query, variables, data: updatedData });
  } catch (err) {
    onNotInCache(err);
  }
}
