import type { ApolloClient } from '@apollo/client';

import { updateItemInCache } from 'frontend/api/cacheHelpers';
import { GetDialogue } from 'frontend/features/Build/graphql';
import { ENTITY } from 'frontend/features/Entities/fragments';
import { ENTITIES, SAMPLES_WITH_ENTITY, SUBSCRIPTIONS_WITH_ENTITIES } from 'frontend/features/Entities/queries';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';
import { fetchIfNecessary } from 'frontend/utils';

const fetchPolicy = 'network-only';

export function samplesDroppedInDialogue({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return async ({ source_dialogue: sourceDialogueId, target_dialogue: targetDialogueId }) => {
    if (!hasBuildPermission) {
      return;
    }

    await client.query({
      query: GetDialogue,
      variables: { ...buildIdObject, dialogueId: sourceDialogueId },
      fetchPolicy,
    });
    await client.query({
      query: GetDialogue,
      variables: { ...buildIdObject, dialogueId: targetDialogueId },
      fetchPolicy,
    });
  };
}

export function entityCreated({
  buildIdObject,
  client,
  hasBuildPermission,
  isBot,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
  isBot: boolean;
}) {
  return (entity) => {
    if (!hasBuildPermission) {
      return;
    }
    fetchIfNecessary({ client, query: ENTITIES, variables: buildIdObject });
    if (isBot && entity.skill) {
      fetchIfNecessary({
        client,
        query: SUBSCRIPTIONS_WITH_ENTITIES,
        variables: buildIdObject,
      });
    }
  };
}

export function entityUpdated({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return (entity) => {
    if (!hasBuildPermission) {
      return;
    }
    updateItemInCache({
      typeName: 'EntityType',
      id: entity.id,
      fragment: ENTITY,
      fragmentName: 'entity',
      variables: { id: entity.id },
      update: (existingEntity) => ({
        ...existingEntity,
        name: entity.name,
        items: entity.items,
      }),
      cache: client.cache,
    });
    fetchIfNecessary({
      query: SAMPLES_WITH_ENTITY,
      variables: { ...buildIdObject, entityId: entity.id },
      client,
    });
  };
}

export function entityDeleted({
  client,
  hasBuildPermission,
}: {
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return ({ id }) => {
    if (!hasBuildPermission) {
      return;
    }

    client.cache.modify({
      fields: {
        entities: (currentEntities, { readField }) =>
          currentEntities.filter((current) => readField('id', current) !== id),
      },
    });

    // FIXME: Find another way to do this https://stackoverflow.com/questions/65595903/apollo-3-deleting-nested-item-from-cache
    updateItemInCache({
      typeName: 'EntityType',
      id,
      fragment: ENTITY,
      fragmentName: 'entity',
      variables: { id },
      update: (existingEntity) => ({ ...existingEntity, id: null }),
      cache: client.cache,
    });
  };
}
