import type { DropDialogueItem, DropFolderItem } from 'frontend/features/Library/components/FolderItem/utils';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

export type DialogueParentVariables =
  | {
      botId?: string;
      skillId?: string;
      topicId?: string;
      parentTopicId?: never;
      parentId?: string;
      dialogueModParentId?: string;
      regular?: true;
    }
  | {
      botId?: string;
      skillId?: string;
      topicId?: never;
      parentTopicId?: string;
      parentId?: never;
      dialogueModParentId?: never;
      regular?: true;
    };

export default function getDialogueParentVariables(
  buildIdObject: BuildIdObject,
  item: DropDialogueItem | DropFolderItem,
): DialogueParentVariables {
  if (item.type === 'DIALOGUE') {
    return {
      ...buildIdObject,
      topicId: item.topicId ?? undefined,
      parentTopicId: undefined,
      parentId: item.parentId,
      dialogueModParentId: item.dialogueModParentId,
      regular: true,
    };
  }

  return {
    ...buildIdObject,
    topicId: undefined,
    parentTopicId: item.parentFolderId ?? undefined,
    parentId: undefined,
    dialogueModParentId: undefined,
    regular: true,
  };
}
