import type { ApolloClient } from '@apollo/client';

import { FolderDocument } from 'frontend/api/generated';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';
import { updateSkillsubscriptionTopics } from 'frontend/routes/hooks/utils/skills';

const fetchPolicy = 'network-only';

export function folderCreated({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return async ({ skill, id: folderId, parentTopicId }: { skill: string; id: string; parentTopicId: string }) => {
    if (!hasBuildPermission) {
      return;
    }

    if (skill) {
      updateSkillsubscriptionTopics({ client, skillId: skill, buildIdObject, parentTopicId });
    } else {
      await client.query({
        query: FolderDocument,
        variables: { ...buildIdObject, folderId },
        fetchPolicy,
      });
    }
  };
}

export function folderUpdated({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return ({ id: folderId, skill: skillId }: { id: string; skill: string }) => {
    if (!hasBuildPermission) {
      return;
    }

    client.query({
      query: FolderDocument,
      variables: { skillId, ...buildIdObject, folderId },
      fetchPolicy,
    });
  };
}

export function folderDeleted({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return ({ skill, parentTopicId }: { skill: string; parentTopicId: string }) => {
    if (!hasBuildPermission) {
      return;
    }

    if (skill) {
      updateSkillsubscriptionTopics({ client, skillId: skill, buildIdObject, parentTopicId });
    }
  };
}
