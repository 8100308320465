import type { TypedDocumentNode } from '@apollo/client';
import type { MutationHookOptions } from '@apollo/client/react/types/types';

import { LibraryDialoguesDocument, LibraryFoldersDocument } from 'frontend/api/generated';

export function operationName(operation: TypedDocumentNode): string {
  // eslint-disable-next-line no-restricted-syntax
  for (const definition of operation.definitions) {
    if (definition.kind === 'OperationDefinition' && definition.name) {
      return definition.name.value;
    }
  }

  throw new Error('Query name not found');
}

export const REFETCH_LIBRARY: Pick<MutationHookOptions, 'refetchQueries'> = {
  refetchQueries: [operationName(LibraryFoldersDocument), operationName(LibraryDialoguesDocument)],
};
