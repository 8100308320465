import type { FolderType } from 'frontend/api/generated';
import { DeleteModal } from 'frontend/features/Modals';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { useDeleteFolder } from './hooks';

function DeleteFolder({
  hide,
  args: { folder, botOrSkillParams },
}: {
  hide: () => void;
  args: {
    folder: Pick<FolderType, 'id' | 'name' | 'parentFolderId'>;
    botOrSkillParams: {
      buildIdObject: BuildIdObject;
      buildType: string;
      buildId: string;
    };
  };
}) {
  const onSubmit = useDeleteFolder({ folder, botOrSkillParams });

  return <DeleteModal onSubmit={onSubmit} hide={hide} name={`folder ${folder.name}`} />;
}

export default DeleteFolder;
