import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';

import { FormErrors, GuardUnsaved, Input, LoaderSwitch, PageBar, ToggleSwitch } from 'frontend/components';
import Panel, { HorizontalRule } from 'frontend/components/Panel';
import { AzureLoginButton, GoogleLoginButton } from 'frontend/features/Login';
import { useToast } from 'frontend/hooks';
import capitalizeFirstLetter from 'frontend/utils/capitalizeFirstLetter';

import styles from '../../styles.scss';
import useOrgSecurity from '../../useOrgSecurity';

const SUBSCRIBE = {};

const Security = () => {
  const toast = useToast();
  const { loading, onSubmit, initialValues } = useOrgSecurity();

  const hasTenantDomain = initialValues?.ssoSettings?.tenantDomain ?? false;

  const onAzureResponse = useCallback(
    (form) =>
      ({ idToken, error, account }) => {
        if (error) {
          toast.error('Unable to authenticate with Azure AD');
          console.error('error from AzureAD-login', { error });
        } else {
          const domain = account.username.split('@')[1];
          form.change('ssoSettings.tenantDomain', domain);
          form.change('ssoSettings.provider', 'MICROSOFT');
          form.change('ssoSettings.active', true);
          form.change('OauthtokenId', idToken);
        }
      },
    [toast],
  );

  const onGoogleResponse = useCallback(
    (form) =>
      ({ credential }) => {
        const identityB64 = credential.split('.')[1];
        // eslint-disable-next-line deprecation/deprecation -- Deprecated for NodeJS, not the Browser.
        const identity = atob(identityB64);
        const { email, error } = JSON.parse(identity);

        if (error) {
          toast.error('Unable to authenticate with G Suite');
          console.error('error from google-login', { error });
        } else {
          const domain = email.split('@')[1];
          form.change('ssoSettings.tenantDomain', domain);
          form.change('ssoSettings.provider', 'GOOGLE');
          form.change('ssoSettings.active', true);
          form.change('OauthtokenId', credential);
        }
      },
    [toast],
  );

  return (
    <LoaderSwitch loading={loading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit, form, values }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={styles.formErrors} />
              <div>
                <Panel side>
                  <h3 className="m-b-md">Force One Time Password</h3>
                  <p>
                    One-time passwords (OTP) are a secure way to authenticate users. Enforce OTP for all of your team
                    members to add an extra layer of security for all of your users. Your users will be required to
                    enter a one-time password in addition to their regular password/SSO when logging in.
                  </p>
                  <ToggleSwitch className="m-r-md" name="otpEnforced" status={['Enforce OTP for every team member']} />
                  <HorizontalRule />
                  <h3 className="m-b-md">Single Sign-on (SSO)</h3>
                  <p>
                    With single sign-on (SSO), your users can access Kindly without having to enter their username and
                    password for each application used in your workspace.
                  </p>

                  {hasTenantDomain || values.ssoSettings ? (
                    <>
                      <span className="m-b-md">
                        <ToggleSwitch
                          name="ssoSettings.active"
                          status={[`Single Sign-on with ${capitalizeFirstLetter(values.ssoSettings.provider)}`]}
                        />
                      </span>
                      <Field
                        component={Input}
                        name="ssoSettings.tenantDomain"
                        label="Allow users with tenant:"
                        readOnly
                      />
                      <p className="m-t-1">
                        <strong>Note:</strong> To switch to a different SSO provider, disable SSO and enable it again.
                      </p>
                    </>
                  ) : (
                    <div className={styles.ssoOptions}>
                      <AzureLoginButton text="Setup Azure AD" onAzureResponse={onAzureResponse(form)} />

                      <GoogleLoginButton onGoogleResponse={onGoogleResponse(form)} />
                    </div>
                  )}
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
};

export default Security;
