/**
 * @deprecated
 * Use lodash omit instead for better typing?
 */
function filterObject<T extends Record<string, unknown>>(condition: (...args: any[]) => boolean, object: T): T {
  return Object.entries(object).reduce(
    (result, [key, value]) => (condition(value, key) ? { ...result, [key]: value } : result),
    {},
  ) as T;
}

export default filterObject;
