import { captureMessage, getCurrentScope, withScope } from '@sentry/browser';
import { type JwtPayload, jwtDecode } from 'jwt-decode';
import mixpanel from 'mixpanel-browser';
import { defineAction } from 'redux-define';

import { toastError } from 'frontend/features/Toast';

/**
 * Authentication using two tokens
 *
 * 1. A refreshToken passed to the browser via an HttpOnly only cookie from /obtain.
 *    This means javascript can't touch this token, but can forward it using `fetch(_, {credentials: 'include'})`.
 *    This token is long lived, ie. days.
 *
 * 2. An accessToken passed to the browser in the response body from /obtain and /refresh.
 *    The accessToken is stored in local storage (to make it available to multiple tabs).
 *    The token is passed in the Authorization header in all API request.
 *    If refresh fails, auth state is cleared and the user is logged out.
 *    This token is short lived, ie. minutes.
 *
 * References:
 *  - [Request flows diagram](https://supertokens.io/static/webflow/blog/securely-manage/images/image113x-p-800.png)
 *  - [XXS and CSRF article on this pattern](http://www.redotheweb.com/2015/11/09/api-security.html)
 * */
const AUTH = defineAction('auth');

export const LOGIN = AUTH.defineAction('LOGIN', ['SUCCESS', 'ACTION', 'ERROR', 'OTP', 'SKIP_OTP']);
export const REFRESH_TOKEN = AUTH.defineAction('REFRESH_TOKEN', ['SUCCESS', 'ERROR']);
export const SIGN_OUT = AUTH.defineAction('SIGN_OUT');

export const initialState = {
  access: undefined,
  authenticated: false,
  error: null,
  isRefreshing: false,
  otpAuthUrl: undefined,
  otpBase32: undefined,
  otpVoluntarySetup: false,
  step: 'login',
};

export const selectAuth = ({ auth }) => auth;
export const selectLoginError = ({ auth }) => auth.error;
export const selectAuthenticated = ({ auth }) => auth.authenticated;
export const isAuthenticated = ({ auth }) => auth.authenticated;
export const selectError = ({ auth }) => auth.error;
export const selectStep = ({ auth }) => auth.step;

let tokenExpTimeout;
const EXPIRY_MARGIN_IN_SECONDS = 10;

function formatLoginError(payload, response) {
  const errorKey = payload && Object.keys(payload) ? Object.keys(payload)[0] : undefined;
  let errorText = 'Incorrect username or password';
  if (response.status === 429) {
    errorText = 'Too many login attempts. Please wait a few minutes.';
  } else if (errorKey && Array.isArray(payload[errorKey])) {
    errorText = payload[errorKey][0];
  } else if (errorKey && typeof payload[errorKey] === 'string') {
    errorText = payload[errorKey];
  }

  return errorText;
}

function handleRefreshError(response, getState, e) {
  if (response.status === 400 && getState().auth.authenticated) {
    withScope((scope) => {
      scope.setExtra('response', JSON.stringify(response));
      captureMessage('user got logged out');
    });
  } else if (response.status !== 401 && getState().auth.authenticated) {
    toastError('Something went wrong with your session, please log in again.');
    withScope((scope) => {
      scope.setExtra('response', JSON.stringify(response));
      scope.setExtra('original_error', JSON.stringify(e));
      captureMessage('unknown error in refresh');
    });
  }
}

const isAccessTokenExpired = (auth) => {
  if (auth.access?.exp) {
    const secondsSinceEpoch = new Date().getTime() / 1000;
    return auth.access.exp - secondsSinceEpoch < EXPIRY_MARGIN_IN_SECONDS;
  }

  return true;
};

const getAccessTokenTimeoutTime = (auth) => {
  const currentTimeInSeconds = new Date().getTime() / 1000;
  const timeDiff = auth.access.exp - currentTimeInSeconds;
  return timeDiff - EXPIRY_MARGIN_IN_SECONDS; // 10 seconds before it expires
};

export const skipOtp = () => async (dispatch) =>
  dispatch({
    type: LOGIN.SKIP_OTP,
  });

export const verifyOtp = (otp) => async (dispatch) => {
  const response = await fetch('/api/v2/auth/session/otp/verify/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ otp }),
  });

  let jsonResponse;
  try {
    jsonResponse = await response.json();
  } catch (e) {
    toastError('Service unavailable. Please try again later');
    return null;
  }

  if (response.status >= 400 && response.status < 500) {
    const errorText = formatLoginError(jsonResponse, response);
    return dispatch({
      type: LOGIN.ERROR,
      payload: errorText,
    });
  }

  return dispatch({
    type: LOGIN.SUCCESS,
    payload: {
      access: {
        token: jsonResponse.access,
        ...jwtDecode(jsonResponse.access),
      },
    },
  });
};

export const startOtpFlow =
  (access: any = undefined) =>
  async (dispatch) => {
    const response = await fetch('/api/v2/auth/session/otp/check/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });

    let payload;
    try {
      payload = await response.json();
    } catch (e) {
      toastError('Service unavailable. Please try again later');
      return undefined;
    }

    if (response.status >= 400 && response.status < 500) {
      const errorText = formatLoginError(payload, response);
      return dispatch({
        type: LOGIN.ERROR,
        payload: errorText,
      });
    }

    return dispatch({
      type: LOGIN.OTP,
      payload: {
        otpVoluntarySetup: Boolean(access),
        otpAuthUrl: payload.otp_auth_url,
        otpBase32: payload.otp_base32,
        access,
      },
    });
  };

export const handleLoginResponse = (response) => async (dispatch) => {
  let jsonResponse;
  try {
    jsonResponse = await response.json();
  } catch (e) {
    toastError('Service unavailable. Please try again later');
    return undefined;
  }

  if (response.status >= 400 && response.status < 500) {
    // When OTP is required (need to set up OTP, or need to enter OTP)
    if (response.status === 422) {
      return dispatch(startOtpFlow());
    }
    const errorText = formatLoginError(jsonResponse, response);
    return dispatch({
      type: LOGIN.ERROR,
      payload: errorText,
    });
  }

  const decoded = jwtDecode<JwtPayload & { user_id: string }>(jsonResponse.access);
  const userId = decoded.user_id;
  const token: undefined | string = jsonResponse?.access?.toString();
  mixpanel.identify(userId);
  getCurrentScope().setUser({ id: userId });

  if (!userId || !token) {
    return dispatch({
      type: LOGIN.ERROR,
      payload: 'Error logging in',
    });
  }

  // Setting up or updating the OTP on login voluntarily
  if (sessionStorage.getItem('setupOtpOnLogin')) {
    sessionStorage.removeItem('setupOtpOnLogin');
    return dispatch(
      startOtpFlow({
        token,
        ...decoded,
      }),
    );
  }
  return dispatch({
    type: LOGIN.SUCCESS,
    payload: {
      access: {
        token: jsonResponse.access,
        ...decoded,
      },
    },
  });
};

export const loginWithUsernameAndPassword = (username: string, password: string) => async (dispatch) => {
  dispatch({ type: LOGIN.ACTION });

  const otpVoluntarySetup = Boolean(sessionStorage.getItem('setupOtpOnLogin'));
  const response = await fetch('/api/v2/auth/session/obtain/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password, setup_otp: otpVoluntarySetup }),
  });

  return dispatch(handleLoginResponse(response));
};

export const loginWithSSO = (provider, token) => async (dispatch) => {
  dispatch({ type: LOGIN.ACTION });

  const otpVoluntarySetup = Boolean(sessionStorage.getItem('setupOtpOnLogin'));
  const response = await fetch(`/api/v2/auth/oauth/${provider}/complete/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_token: token, setup_otp: otpVoluntarySetup }),
  });

  return dispatch(handleLoginResponse(response));
};

export const logout = (client, skipRequest = false) => {
  client?.clearStore();

  if (tokenExpTimeout) clearTimeout(tokenExpTimeout);

  if (!skipRequest) {
    fetch('/api/v2/auth/session/logout/', {
      credentials: 'include',
      method: 'POST',
    });
  }

  return {
    type: SIGN_OUT.ACTION,
  };
};

const refreshAccessToken = () => async (dispatch, getState) => {
  /** Don't export refreshAccessToken, use getAccessToken which queues calls */
  dispatch({
    type: REFRESH_TOKEN.ACTION,
  });

  const response = await fetch('/api/v2/auth/session/refresh/', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  let payload;
  try {
    payload = await response.json();
    if (response.status !== 200) {
      throw new Error('Refresh unsuccessful');
    }
  } catch (e) {
    handleRefreshError(response, getState, e);
    return dispatch({
      type: REFRESH_TOKEN.ERROR,
      payload: e?.message,
    });
  }

  const decoded = jwtDecode<JwtPayload & { user_id: string }>(payload.access);
  const userId = decoded.user_id;
  mixpanel.identify(userId);
  getCurrentScope().setUser({ id: userId });

  return dispatch({
    type: REFRESH_TOKEN.SUCCESS,
    payload: {
      token: payload.access,
      ...decoded,
    },
  });
};

export const getAccessToken =
  () =>
  async (dispatch, getState): Promise<string | undefined> => {
    const { auth } = getState();

    if (!auth?.access?.exp || !auth?.access?.token) return undefined;

    if (auth.isRefreshing) {
      // something already called getAccessToken and is refreshing it, wait...
      await new Promise((resolve) => {
        setTimeout(resolve, 200);
      });
      return getAccessToken()(dispatch, getState);
    }

    if (tokenExpTimeout) clearTimeout(tokenExpTimeout);

    /*
     * If the user is idle, we preemptively renew the refresh token due to its short lived expiry.
     * TODO: If the user is not on the page for any reason for longer than the refresh token expire,
     * then the user is going to be logged out, since there is no valid JWT token to exchange with the server.
     * */
    tokenExpTimeout = setTimeout(
      () => getAccessToken()(dispatch, getState),
      Math.max(getAccessTokenTimeoutTime(auth), EXPIRY_MARGIN_IN_SECONDS * 1000),
    );

    if (isAccessTokenExpired(auth)) {
      const refreshedToken = await dispatch(refreshAccessToken());
      return refreshedToken.payload.token;
    }

    return auth.access.token;
  };

export const setLoginError = (error) => ({
  type: LOGIN.ERROR,
  payload: error,
});

export const setLoginSuccess = (access) => (dispatch) => {
  const decoded = jwtDecode<JwtPayload & { user_id: string }>(access);
  getCurrentScope().setUser({ id: decoded.user_id });

  return dispatch({
    type: LOGIN.SUCCESS,
    payload: {
      access: {
        token: access,
        ...decoded,
      },
    },
  });
};

export const setLoginOTP = (otpAuthUrl, otpBase32, otpVoluntarySetup) => (dispatch) =>
  dispatch({
    type: LOGIN.OTP,
    otpAuthUrl,
    otpBase32,
    otpVoluntarySetup,
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.SUCCESS:
      return {
        step: 'success',
        access: {
          ...action.payload.access,
        },
        otpVoluntarySetup: undefined,
        otpAuthUrl: undefined,
        otpBase32: undefined,
        authenticated: true,
        error: null,
      };
    case LOGIN.ACTION:
      return {
        ...state,
        error: null,
      };
    case LOGIN.OTP:
      return {
        ...state,
        step: 'otp',
        access: {
          ...action.payload.access,
        },
        otpAuthUrl: action.payload.otpAuthUrl,
        otpBase32: action.payload.otpBase32,
        otpVoluntarySetup: action.payload.otpVoluntarySetup,
        authenticated: false,
        error: null,
      };
    case LOGIN.SKIP_OTP:
      return {
        ...state,
        step: 'success',
        authenticated: true,
        otpVoluntarySetup: undefined,
        otpAuthUrl: undefined,
        otpBase32: undefined,
        error: null,
      };
    case LOGIN.ERROR:
      return {
        ...initialState,
        step: state.step,
        otpAuthUrl: state.otpAuthUrl,
        otpBase32: state.otpBase32,
        otpVoluntarySetup: state.otpVoluntarySetup,
        error: {
          type: action.type,
          message: action.payload,
        },
      };
    case REFRESH_TOKEN.ACTION:
      return {
        ...state,
        isRefreshing: true,
      };
    case REFRESH_TOKEN.SUCCESS:
      return {
        ...state,
        access: {
          ...action.payload,
        },
        error: null,
        authenticated: true,
        isRefreshing: false,
      };
    case REFRESH_TOKEN.ERROR:
      return {
        ...initialState,
        error: {
          type: action.type,
          message: action.payload,
        },
      };
    case SIGN_OUT.ACTION:
      return initialState;
    default:
      return state;
  }
};
