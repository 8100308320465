import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UpdateOneTimePasswordDocument } from 'frontend/api/generated';
import { Modal } from 'frontend/features/Modals';
import { useToast } from 'frontend/hooks';
import { logout } from 'frontend/state/dux/auth';

import styles from './styles.scss';

interface UpdateOTPModalProps {
  hide(): Promise<void>;
}

interface CurriedProps {
  deleteOtp: boolean;
}

const UpdateOTPModal = ({ deleteOtp }: CurriedProps) => {
  const Component = ({ hide }: UpdateOTPModalProps) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [updateOTP] = useMutation(UpdateOneTimePasswordDocument);

    const onOk = useCallback(async () => {
      await updateOTP();
      if (deleteOtp) {
        toast.success('OTP was deleted.');
        return undefined;
      }

      toast.success('Please log in again to create or set up a new OTP.');
      dispatch(logout(client));
      sessionStorage.setItem('setupOtpOnLogin', '1');
      return undefined;
    }, [updateOTP, toast, dispatch, client]);

    return (
      <Modal title="Update OTP" onOk={onOk} hide={hide}>
        <Modal.Content className={styles.infoModalContent}>
          <h4 className="m-b-sm">Warning</h4>
          {deleteOtp ? (
            <p>Are you sure you want to delete your One Time Password?</p>
          ) : (
            <p>
              Creating or updating your One Time Password (OTP) will log you out and you will be asked to log in again.
              Do you want to continue?
            </p>
          )}
        </Modal.Content>
      </Modal>
    );
  };

  Component.displayName = 'UpdateOTPModal';
  return Component;
};

export default UpdateOTPModal;
