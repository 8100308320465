import type { FetchResult } from '@apollo/client';

import type {
  BuildDialogueLegacyDialogueTypeChoices,
  DropDialogueInDialogueMutation,
  DropDialogueInDialogueMutationVariables,
} from 'frontend/api/generated';
import { isRegularDialogueType } from 'frontend/constants';
import type { DropDialogueItem } from 'frontend/features/Library/components/FolderItem/utils';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

export async function dropDialogue({
  id,
  toast,
  buildIdObject,
  skillId,
  dialogueInDialogue,
  item,
  currentTitle,
  currentLanguage,
}: {
  id: string;
  toast: any;
  buildIdObject: BuildIdObject;
  skillId?: string;
  dialogueInDialogue: ({
    variables,
  }: {
    variables: DropDialogueInDialogueMutationVariables;
  }) => Promise<FetchResult<DropDialogueInDialogueMutation>>;
  item: DropDialogueItem;
  currentTitle: string;
  currentLanguage: string;
}) {
  try {
    await dialogueInDialogue({
      variables: { sourceDialogueId: item.id, targetDialogueId: id, skillId, ...buildIdObject },
    });
  } catch (error) {
    toast.error(error.message);
    return;
  }

  toast.success(`Dialogue '${item.title[currentLanguage]}' was made a followup to '${currentTitle}'`);
}

export function canDrop({ id, dialogueType }: { id: string; dialogueType: BuildDialogueLegacyDialogueTypeChoices }) {
  return (item: DropDialogueItem) => {
    if (item.type === 'DIALOGUE') {
      return (
        Boolean(item.topicId !== id || item.parentId || item.dialogueModParentId) && isRegularDialogueType(dialogueType)
      );
    }

    return false;
  };
}
