import { Field } from 'react-final-form';

import { Checkbox, Input } from 'frontend/components';
import { ModalForm } from 'frontend/features/Modals';
import { chain, max, required } from 'frontend/form/validators';

export default function FolderForm({
  hide,
  onSubmit,
  actionName,
  initialValues,
}: {
  hide: (...args: any[]) => any;
  onSubmit: (...args: any[]) => any;
  actionName: string;
  initialValues: Record<string, unknown>;
}) {
  return (
    <ModalForm onSubmit={onSubmit} hide={hide} initialValues={initialValues} title={`${actionName} folder`}>
      <Field
        component={Input}
        label="Name"
        name="name"
        className="m-b-lg"
        placeholder="Name"
        inputLimit={200}
        validate={chain(required, max(200))}
        autoFocus
      />
      <Field component={Checkbox} type="checkbox" name="isActive" label="Active" />
    </ModalForm>
  );
}
