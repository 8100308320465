import RequestDetails from 'frontend/features/DebugConsole/components/RequestDetails';
import { Modal } from 'frontend/features/Modals';

import styles from './styles.scss';
import type { WebhookRequest } from '../../hooks/useWebhookRequests';

interface WebhookDebugInfoProps {
  args: WebhookRequest;
  hide: () => void;
}

const WebhookDebugInfo = ({ args: data, hide }: WebhookDebugInfoProps) => (
  <Modal title="Webhook Response Debug Info" className={styles.modal} hide={hide} footer={false} hideCancel>
    <Modal.Content className={styles.content}>{data && <RequestDetails collapsed={false} req={data} />}</Modal.Content>
  </Modal>
);

export default WebhookDebugInfo;
