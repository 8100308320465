import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';

import {
  OrganizationSettingsDocument,
  type OrganizationSettingsQuery,
  UpdateOrganizationSecurityDocument,
} from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';
import { formatSubmitErrors } from 'frontend/utils';

export interface InitialValues {
  id?: OrganizationSettingsQuery['organization']['id'];
  ssoSettings?: OrganizationSettingsQuery['organization']['ssoSettings'];
  otpEnforced?: OrganizationSettingsQuery['organization']['otpEnforced'];
}

interface HookReturn {
  loading: boolean;
  onSubmit: (values: any) => Promise<any>;
  initialValues: InitialValues;
}

export default function useOrgSecurity(): HookReturn {
  const { organizationId: id } = useParams();

  if (!id) {
    throw new Error('Organization ID is required');
  }

  const toast = useToast();
  const { data, loading } = useQuery(OrganizationSettingsDocument, { variables: { id } });
  const [updateSettings] = useMutation(UpdateOrganizationSecurityDocument);

  const initialValues = {
    id: data?.organization.id,
    ssoSettings: data?.organization.ssoSettings,
    otpEnforced: data?.organization.otpEnforced,
  };

  const onSubmit = async (values) => {
    const ssoSettingsDirty = Object.keys(values.ssoSettings || {}).some(
      (key) => values.ssoSettings[key] !== initialValues.ssoSettings?.[key],
    );

    const variables = {
      id,
      ...(ssoSettingsDirty && {
        ssoSettings: {
          ...omit(values.ssoSettings, ['__typename', 'id']),
          tokenId: values.OauthtokenId,
          tenantDomain: values?.ssoSettings?.tenantDomain || '',
          provider: values?.ssoSettings?.provider || '',
          active: Boolean(values?.ssoSettings?.active),
        },
      }),
      otpEnforced: Boolean(values?.otpEnforced),
    };

    try {
      await updateSettings({
        variables,
        update: () => {
          toast.success('Organization security updated');
        },
      });
    } catch (err) {
      return formatSubmitErrors(err);
    }
    return undefined;
  };

  return { loading, onSubmit, initialValues };
}
