import { omit } from 'lodash';

import { URL_DIALOGUE_TYPES, type UrlDialogueType } from '../constants';

export type URLTriggerInput = {
  urlTrigger: Record<string, string>;
  urlTriggerType: Record<string, UrlDialogueType>;
};

export default function handleUrlTrigger(
  currentLanguage: string,
  { urlTrigger, urlTriggerType }: URLTriggerInput,
): URLTriggerInput {
  // When the URL trigger is not defined for the current language, we also remove the URL trigger type
  if (!urlTrigger?.[currentLanguage]) {
    urlTrigger = omit(urlTrigger, currentLanguage);
    urlTriggerType = omit(urlTriggerType, currentLanguage);
  }

  // When the URL trigger is defined for the current language but the URL trigger type is not, we default to URL path match
  if (urlTrigger?.[currentLanguage] && !urlTriggerType?.[currentLanguage]) {
    urlTriggerType = {
      ...urlTriggerType,
      [currentLanguage]: URL_DIALOGUE_TYPES.URL_PATH_MATCH,
    };
  }

  return { urlTrigger, urlTriggerType };
}
