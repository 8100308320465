import React, { useRef, useState } from 'react';

import { Send } from 'frontend/assets/icons';
import { Icon, Input } from 'frontend/components';
import { FIELD_COLOR } from 'frontend/constants';

import styles from './CommentsInput.scss';
import useCommentsMutation from '../../hooks/useCommentsMutation';

const CommentsInput = () => {
  const { createComment } = useCommentsMutation();
  const [commentText, setCommentText] = useState<string | undefined>();

  const inputRef = useRef<HTMLInputElement>(null);
  const createNewComment = async (text: string) => {
    if (text) {
      await createComment(text);
      setCommentText('');
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };
  return (
    <div className={styles.commentsNewComment}>
      <Input
        multiline
        ref={inputRef}
        input={{
          name: 'new-comment',
          onChange: (e) => {
            setCommentText(e.target.value);
          },
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey && commentText?.trim()) {
            createNewComment(commentText);
            e.preventDefault();
          }
        }}
        inputClassName={styles.commentsNewCommentInput}
        placeholder="Write a comment..."
        minRows={1}
        maxRows={10}
        fieldColor={FIELD_COLOR.MISCHKA}
      />
      <Icon
        className={styles.commentsSendIcon}
        onClick={() => {
          if (commentText) {
            createNewComment(commentText);
          }
        }}
        hoverColor="default"
        component={Send}
        color="darkGray"
      />
    </div>
  );
};

export default CommentsInput;
