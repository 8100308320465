import SystemDialogueFolder from 'frontend/features/Library/components/SystemDialogueFolder';
import { useBotOrSkill } from 'frontend/hooks';

const SystemView = () => {
  const { buildIdObject } = useBotOrSkill();

  return <SystemDialogueFolder buildIdObject={buildIdObject!} />;
};

export default SystemView;
