import { useApolloClient, useMutation } from '@apollo/client';
import { EditorState } from 'draft-js';
import { pick } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DialogueListDocument } from 'frontend/api/generated';
import { LoaderSwitch } from 'frontend/components';
import { DIALOGUE_TYPES } from 'frontend/constants';
import getSmartReplyUpdate from 'frontend/features/Build/utils/getSmartReplyUpdate';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { useBotOrSkill, useCurrentLanguage, useLanguages, useQueryParams, useToast } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';
import { fetchIfNecessary, mapToObject } from 'frontend/utils';

import { DialogueForm } from '../components';
import { CreateDialogueMutation, GetDialogue } from '../graphql';
import {
  dialogueInputFields,
  emptyButton,
  getBuildItemUpdate,
  getButtonsUpdate,
  getImageCarouselsUpdate,
  setupInitialImageCarouselSize,
} from '../utils';
import getEmptyReply from '../utils/getEmptyReply';
import getFormUpdate from '../utils/getFormUpdate';
import getRulesUpdate from '../utils/getRulesUpdate';
import { prepareDialogueBuildItemForBE } from '../utils/prepareDialogueBuildItem';

const NEW_PATTERN = { pattern: '', case_sensitive: false };

const CreateDialogue = () => {
  const toast = useToast();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { dialogueType, parentId, dialogueModParentId, topicId } = Object.fromEntries(useQueryParams());
  const [allowRedirect, setAllowRedirect] = useState(false);
  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();
  const { isBot, buildId, buildIdObject, buildType }: BotOrSkill = useBotOrSkill();
  const { loading, languagesAndVariants } = useLanguages({
    isBot,
    buildIdObject,
    buildType,
  } as BotOrSkill);
  const isFeatureEnabled = useFeatureFlags();
  const [createDialogueMutation] = useMutation(CreateDialogueMutation, REFETCH_LIBRARY);
  const [hasChanges, setHasChanges] = useState(false);

  const slotsEnabled = isFeatureEnabled('slots');

  const initialValues = useMemo(() => {
    if (loading) {
      return {};
    }
    const replyEntitiesEnabled = isBot && slotsEnabled;

    const values: Record<string, unknown> = {
      dialogueType,
      replies: [getEmptyReply(selectedLanguage, undefined, replyEntitiesEnabled)],
      isActive: mapToObject(({ code }) => ({ [code]: code === selectedLanguage }), languagesAndVariants),
      imageCarouselSize: setupInitialImageCarouselSize(languagesAndVariants),
      newButton: emptyButton,
      buttonsDisplayOrientation: 'VERTICAL',
      buildForms: [],
      dialogueRules: [],
      patterns: undefined,
      samples: undefined,
      newSample: undefined,
      outputSlots: undefined,
    };

    if (dialogueType === DIALOGUE_TYPES.PATTERNS) {
      values.patterns = { [currentLanguage]: [NEW_PATTERN] };
    }
    if (dialogueType === DIALOGUE_TYPES.SAMPLES) {
      values.samples = [];
    }
    if (dialogueType === DIALOGUE_TYPES.INTENT) {
      values.intent = '';
    }
    if (dialogueType === DIALOGUE_TYPES.SAMPLES) {
      values.newSample = { editorState: EditorState.createEmpty() };
    }
    if (slotsEnabled) {
      values.outputSlots = [];
    }

    return values;
  }, [loading, isBot, slotsEnabled, dialogueType, selectedLanguage, languagesAndVariants, currentLanguage]);

  const onSubmit = useCallback(
    async (values, form) => {
      const { initialValues: initial } = form.getState();
      const { createdSamples } = getBuildItemUpdate(initial, values, 'samples');
      const { updatedReplies: createdReplies } = getBuildItemUpdate(initial, values, 'replies');
      const { createdSmartReplies } = getSmartReplyUpdate(initial, values, 'smartReplies');

      const formUpdate = getFormUpdate(initial, values);

      setAllowRedirect(true); // Skip the Prompt, wasn't working when put after the mutation

      const imageCarouselsUpdate = getImageCarouselsUpdate(undefined, values);
      const buttonsUpdate = getButtonsUpdate(undefined, values);
      const rulesUpdate = getRulesUpdate({ initialValues: initial, values, language: selectedLanguage });
      const context = prepareDialogueBuildItemForBE(values.context);
      const advancedOptions = prepareDialogueBuildItemForBE(values.advancedOptions);

      const dialogueInput = {
        context,
        ...pick(values, dialogueInputFields),
        advancedOptions,
        createdSamples,
        createdReplies,
        createdSmartReplies,
        ...formUpdate,
        ...buttonsUpdate,
        ...imageCarouselsUpdate,
        ...rulesUpdate,
        createdOutputSlots: values.outputSlots,
        title: values.title,
      };

      const variables = {
        ...buildIdObject,
        parentId,
        topicId,
        dialogueModParentId,
        dialogueInput,
        languageCode: selectedLanguage,
      };

      const { data } = await createDialogueMutation({ variables });
      const { createDialogue: dialogue } = data;

      client.writeQuery({
        query: GetDialogue,
        variables: { ...buildIdObject, dialogueId: dialogue.id },
        data: { dialogue },
      });

      fetchIfNecessary({
        client,
        query: DialogueListDocument,
        variables: { ...buildIdObject, includeSubscribedSkills: true },
      });
      fetchIfNecessary({
        client,
        query: DialogueListDocument,
        variables: { ...buildIdObject, dialogueType: DIALOGUE_TYPES.SAMPLES },
      });

      toast.success('Dialogue created!');
      const url = getBuildUrl({ buildIdObject, dialogueType: DIALOGUE_TYPES.REGULAR, target: dialogue.id });
      navigate(url);
    },
    [
      buildIdObject,
      parentId,
      topicId,
      dialogueModParentId,
      selectedLanguage,
      createDialogueMutation,
      client,
      toast,
      navigate,
    ],
  );

  return (
    <LoaderSwitch size="large" loading={loading}>
      <DialogueForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        selectedLanguage={selectedLanguage}
        allowRedirect={allowRedirect}
        parentId={parentId}
        buildIdObject={buildIdObject!}
        buildType={buildType!}
        buildId={buildId!}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        currentRuleId={undefined}
      />
    </LoaderSwitch>
  );
};

export default CreateDialogue;
