import { defineAction } from 'redux-define';

const COMMENTS = defineAction('comments');

export const TOGGLE_COMMENTS = COMMENTS.defineAction('TOGGLE_COMMENTS');

export const selectComments = ({ comments }) => comments;

export const toggleComments = () => ({
  type: TOGGLE_COMMENTS.ACTION,
});

const initialState = {
  open: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COMMENTS.ACTION:
      return { ...state, open: !state.open };
    default:
      return state;
  }
};
