import { useQuery } from '@apollo/client';
import { capitalize } from 'lodash';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { SeasonsDocument } from 'frontend/api/generated';
import { PaintbucketTilted, Refresh, Text } from 'frontend/assets/icons';
import defaultAvatar from 'frontend/assets/images/member_avatar.svg?url';
import {
  Button,
  ColorPickerInput,
  Condition,
  FileUpload,
  FormErrors,
  GuardUnsaved,
  Input,
  LoaderSwitch,
  PageBar,
  Panel,
  Select,
  SelectLanguage,
  ToggleSwitch,
} from 'frontend/components';
import ChatPreview from 'frontend/features/BotSettings/Chatbubble/assets/ChatPreview';
import MediaFormsPreview from 'frontend/features/BotSettings/Chatbubble/assets/MediaFormsPreview';
import MessageTextPreview from 'frontend/features/BotSettings/Chatbubble/assets/MessageTextPreview';
import { useChatbubbleForm } from 'frontend/features/BotSettings/hooks';
import { chain, hex, required } from 'frontend/form/validators';
import { useCurrentLanguage } from 'frontend/hooks';
import useFeatureFlags from 'frontend/hooks/useFeatureFlags';

import styles from './styles.scss';
import GlobalIconPreview from '../../assets/GlobalIconPreview';
import chatBubbleStyles from '../../styles.scss';

const colorValidator = chain([required, hex]);

const SUBSCRIBE = {};
const OUTLINE_STATUS = ['Use outline'];
const USE_TEXT = ['Use text on chat bubble'];

const falsyToNull = (x) => x || null;

export default function Appearance() {
  const { data: seasonsData, loading: seasonsLoading } = useQuery(SeasonsDocument);

  const { botId } = useParams();

  const isFeatureEnabled = useFeatureFlags();
  const isGlobalIconsEnabled = isFeatureEnabled('global-icons');
  const [{ currentLanguage }] = useCurrentLanguage();

  const { onSubmitWithUpload, initialValues, restoreDefaultColors, loading, languagesLoading, languages, setFile } =
    useChatbubbleForm({ uploadUrl: `api/v2/bot/${botId}/upload_chatbubble_icon_avatar/` });

  return (
    <LoaderSwitch loading={loading || languagesLoading || seasonsLoading} size="large">
      <Form
        onSubmit={onSubmitWithUpload}
        initialValues={initialValues}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit, form: { change }, values }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={(chatBubbleStyles.chatbubbleSettingsWrapper, styles.wrapper)}>
                <Panel>
                  <div className={chatBubbleStyles.selectLanguage}>
                    {languages.length > 1 && <SelectLanguage languages={languages} supportVariants={false} />}
                  </div>
                  <div>
                    <h3>Appearance</h3>
                    <p className={styles.sectionDescription}>
                      Chatbots want to look good, too. Give your bot some fancy colors!
                    </p>
                    <div className={styles.gridContainer}>
                      <div>
                        <div>
                          <label htmlFor="colorHeaderBackground">Top bar background</label>
                          <ColorPickerInput
                            id="ColorPickerInput-header-background"
                            name="colorHeaderBackground"
                            placeholder="#0069ff"
                            aria-label="Header background"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                        <div>
                          <label htmlFor="colorHeaderText">Top bar foreground</label>
                          <ColorPickerInput
                            id="ColorPickerInput-header-text"
                            name="colorHeaderText"
                            placeholder="#ffffff"
                            aria-label="Header text"
                            validate={colorValidator}
                            icon={Text}
                          />
                        </div>
                        <div className={styles.appearanceItemSeparator}>
                          <label htmlFor="colorBackground">Chat background</label>
                          <ColorPickerInput
                            id="ColorPickerInput-background"
                            name="colorBackground"
                            placeholder="#ffffff"
                            aria-label="Restore to default"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                        <div className={styles.appearanceItemSeparator}>
                          <label htmlFor="colorBubbleButtonBackground">Chat bubble</label>
                          <ColorPickerInput
                            id="ColorPickerInput-bubble-button-background"
                            name="colorBubbleButtonBackground"
                            placeholder="#0069ff"
                            aria-label="Chat bubble"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                        <div>
                          <ToggleSwitch name="useBubbleOutline" className="m-r-md" status={OUTLINE_STATUS} />
                        </div>
                        <Condition when="useBubbleOutline" is>
                          <div className={styles.outlinePicker}>
                            <label htmlFor="colorButtonOutline">Chat bubble outline</label>
                            <ColorPickerInput
                              id="ColorPickerInput-button-outline"
                              name="colorButtonOutline"
                              placeholder="#ffffff"
                              aria-label="Text input background"
                              validate={colorValidator}
                              icon={PaintbucketTilted}
                            />
                          </div>
                        </Condition>
                        <div className={styles.appearanceItemSeparator}>
                          <label htmlFor="colorButtonOutline">Chat bubble text color</label>
                          <ColorPickerInput
                            id="ColorPickerInput-chatbubble-text-color"
                            name="colorChatbubbleText"
                            placeholder="#ffffff"
                            aria-label="Chat bubble text color"
                            validate={colorValidator}
                            icon={Text}
                          />
                        </div>
                        <div className={styles.appearanceItemSeparator}>
                          <ToggleSwitch name="useChatbubbleText" className="m-r-md" status={USE_TEXT} />
                        </div>
                        <Condition when="useChatbubbleText" is>
                          <div className={styles.appearanceInput}>
                            <Field
                              component={Input}
                              id="Input-chat-button-text"
                              name={`chatbubbleText.${currentLanguage}`}
                              aria-label="chat title"
                              placeholder="Chat"
                              inputLimit={20}
                              label="Chat title"
                            />
                          </div>
                        </Condition>
                      </div>
                      <ChatPreview
                        chatBackground={values.colorBackground}
                        topBarBackground={values.colorHeaderBackground}
                        topBarForeground={values.colorHeaderText}
                        chatbubbleColor={values.colorBubbleButtonBackground}
                        chatbubbleOutline={
                          values.useBubbleOutline ? values.colorButtonOutline : values.colorBubbleButtonBackground
                        }
                        chatbubbleTextEnabled={values.useChatbubbleText}
                        chatBubbleTextColor={values.colorChatbubbleText}
                        chatbubbleText={values.chatbubbleText?.[currentLanguage]}
                      />
                    </div>
                  </div>
                  <Panel.Separator />
                  <div>
                    <h3>Messages & text</h3>
                    <p className={styles.sectionDescription}>
                      Coloring the main content in your chat to suite your need brand as well as considering great
                      readability is crusial for a good user experiance.
                    </p>
                    <div className={styles.gridContainer}>
                      <div>
                        <div className={styles.appearanceItemSeparator}>
                          <label htmlFor="colorUserMessageBackground">User message background</label>
                          <ColorPickerInput
                            id="ColorPickerInput-user-message-background"
                            name="colorUserMessageBackground"
                            placeholder="#0069ff"
                            aria-label="User message background"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                        <div className={styles.appearanceItemSeparator}>
                          <label htmlFor="colorBotMessageBackground">Bot message background</label>
                          <ColorPickerInput
                            id="ColorPickerInput-bot-message-background"
                            name="colorBotMessageBackground"
                            placeholder="#f5f5f5"
                            aria-label="Bot message background"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                        <div>
                          <label htmlFor="colorButtonBackground">Button background</label>
                          <ColorPickerInput
                            id="ColorPickerInput-button-background"
                            name="colorButtonBackground"
                            placeholder="#0069ff"
                            aria-label="Button background"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                        <div>
                          <label htmlFor="colorButtonText">Button text</label>
                          <ColorPickerInput
                            name="colorButtonText"
                            id="ColorPickerInput-button-text"
                            placeholder="#ffffff"
                            aria-label="Button text"
                            validate={colorValidator}
                            icon={Text}
                          />
                        </div>
                      </div>
                      <MessageTextPreview
                        buttonBackground={values.colorButtonBackground}
                        userMessageBackground={values.colorUserMessageBackground}
                        buttonTextColor={values.colorButtonText}
                        botMessageBackground={values.colorBotMessageBackground}
                        chatBackground={values.colorBackground}
                      />
                    </div>
                  </div>
                  <Panel.Separator />
                  <div>
                    <h3>Media & Forms</h3>
                    <p className={styles.sectionDescription}>
                      Coloring the main content in your chat to suite your need brand as well as considering great
                      readability is crusial for a good user experiance.
                    </p>
                    <div className={styles.gridContainer}>
                      <div>
                        <div>
                          <label htmlFor="colorPanelBackground">Panel color</label>
                          <ColorPickerInput
                            id="ColorPickerInput-panel"
                            name="colorPanelBackground"
                            placeholder="#0069ff"
                            aria-label="Panel color"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                        <div className={styles.appearanceItemSeparator}>
                          <label htmlFor="colorSecondaryButtonBackground">Secondary button background</label>
                          <ColorPickerInput
                            id="ColorPickerInput-secondary-button-background"
                            name="colorSecondaryButtonBackground"
                            placeholder="#0069ff"
                            aria-label="Secondary button color"
                            validate={colorValidator}
                            icon={PaintbucketTilted}
                          />
                        </div>
                      </div>
                      <MediaFormsPreview
                        buttonBackground={values.colorButtonBackground}
                        buttonTextColor={values.colorButtonText}
                        chatBackground={values.colorBackground}
                        panelBackground={values.colorPanelBackground}
                        secondaryButtonBackground={values.colorSecondaryButtonBackground}
                      />
                    </div>
                  </div>
                  <Panel.Separator />
                  {isGlobalIconsEnabled && (
                    <>
                      <div>
                        <h3>Global icons</h3>
                        <p className={styles.sectionDescription}>
                          Coloring the main content to your global icon menu to suite your brand as well as considering
                          great readability is crucial for a good user experiance.
                        </p>
                        <div className={styles.gridContainer}>
                          <div>
                            <div>
                              <label htmlFor="colorGlobalIconsButtonBackground">Button background</label>
                              <ColorPickerInput
                                id="ColorPickerInput-global-icon-button-background"
                                name="colorGlobalIconsButtonBackground"
                                placeholder="#FFFFFF"
                                aria-label="Global icon button background"
                                validate={colorValidator}
                                icon={PaintbucketTilted}
                              />
                            </div>
                          </div>
                          <GlobalIconPreview
                            buttonBackground={values.colorGlobalIconsButtonBackground}
                            chatbubbleColor={values.colorBubbleButtonBackground}
                            chatbubbleOutline={
                              values.useBubbleOutline ? values.colorButtonOutline : values.colorBubbleButtonBackground
                            }
                            chatbubbleTextEnabled={values.useChatbubbleText}
                            chatBubbleTextColor={values.colorChatbubbleText}
                            chatbubbleText={values.chatbubbleText?.[currentLanguage]}
                          />
                        </div>
                      </div>
                      <Panel.Separator />
                    </>
                  )}
                  <h3 className="m-b-lg">Other styling options</h3>
                  <p className="text-color-light m-t-4 m-b-2">Upload image/avatar to the Chatbubble icon</p>
                  <div className={styles.avatarField}>
                    <div
                      className={styles.avatar}
                      style={{
                        backgroundImage: `url(${values.chatbubbleIconAvatarUrl || defaultAvatar})`,
                      }}
                    />
                    <div className="flex-column">
                      <Field component={Input} name="chatbubbleIconAvatarUrl" hidden />
                      <FileUpload
                        text="Upload picture"
                        accept="image/png,image/jpeg"
                        onUpload={([rf]) => {
                          const { source, file } = rf!;
                          change('chatbubbleIconAvatarUrl', source as string);
                          setFile!(file);
                        }}
                      />
                      {!!values.chatbubbleIconAvatarUrl && (
                        <Button onClick={() => change('chatbubbleIconAvatarUrl', null)} flat>
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                  <p className="text-color-light m-t-4 m-b-2">Decorate your chat button with a seasonal appearance:</p>
                  <Field
                    component={Select}
                    name="seasonalAppearance"
                    wrapperClassName={styles.seasons}
                    parse={falsyToNull}
                  >
                    <>
                      <Select.Option key="season-none" value="" label="None" />
                      {seasonsData?.seasons?.map((season) => (
                        <Select.Option key={`season-${season}`} value={season} label={capitalize(season)} />
                      ))}
                    </>
                  </Field>
                  <Panel.Separator />
                  <h3>Restore colors to default value</h3>
                  <p>This will restore your color profile to Kindly’s default color palette</p>
                  <Button color="warning" onClick={() => restoreDefaultColors(change)} className="m-b-1" icon={Refresh}>
                    Restore colors
                  </Button>
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
}
