import { useQuery } from '@apollo/client';

import { GET_BOT_KEY } from 'frontend/api/queries';

import KindlyChat from './KindlyChat';

interface ChatClientProps {
  botId: string;
}

const ChatClient = ({ botId }: ChatClientProps) => {
  const { data: botKeyData, loading: botKeyLoading } = useQuery(GET_BOT_KEY, { variables: { botId } });

  if (botKeyLoading) return null;
  return <KindlyChat botKey={botKeyData.bot.botKey} />;
};

export default ChatClient;
