import type { ApolloClient } from '@apollo/client';

import removeFieldsFromKeyWithPotentialVariables from 'frontend/api/cacheHelpers/removeFieldsFromKeyWithPotentialVariables';
import { IMPORT_STATUSES } from 'frontend/features/BotSettings/constants';
import { toastError, toastSuccess } from 'frontend/features/Toast';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

export function onWorkspaceImport({
  buildIdObject,
  client,
  hasBuildPermission,
  userId,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
  userId: string;
}) {
  return ({ status, triggeredByUserId }) => {
    if (!hasBuildPermission) {
      return;
    }

    if (status.toUpperCase() === IMPORT_STATUSES.ERROR && userId === triggeredByUserId) {
      toastError('Workspace import failed');
    }

    if (status.toUpperCase() !== IMPORT_STATUSES.IMPORTED) {
      return;
    }

    const fieldsToClear = [
      'dialogues',
      'dialogue',
      'numberOfPatternsInOtherDialogues',
      'buttons',
      'dialoguesWithWebhooks',
      'someDialogue',
      'buildStatistics',
    ];

    removeFieldsFromKeyWithPotentialVariables(client, fieldsToClear, buildIdObject);

    if (userId === triggeredByUserId) {
      toastSuccess('Workspace imported successfully');
    }
  };
}
