import type { FetchResult } from '@apollo/client';

import type {
  DialogueType,
  DropDialogueInFolderMutation,
  DropDialogueInFolderMutationVariables,
  DropFolderInFolderMutation,
  DropFolderInFolderMutationVariables,
  FolderType,
} from 'frontend/api/generated';
import { isRegularDialogueType } from 'frontend/constants';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

export type DropDialogueItem = Pick<
  DialogueType,
  'id' | 'title' | 'topicId' | 'parentId' | 'dialogueModParentId' | 'dialogueType'
> & {
  type: 'DIALOGUE';
};

export type DropFolderItem = Pick<FolderType, 'id' | 'name' | 'parentFolderId'> & { type: 'FOLDER' };

export function canDrop({ isSubscription, id }: { isSubscription: boolean; id: string }) {
  return (item: DropDialogueItem | DropFolderItem): boolean => {
    if (isSubscription) {
      return false;
    }

    if (item.type === 'FOLDER') {
      return ![item.id, item.parentFolderId].includes(id);
    }

    if (item.type === 'DIALOGUE') {
      return (
        (item.topicId !== id || item.parentId || item.dialogueModParentId) && isRegularDialogueType(item.dialogueType)
      );
    }

    return false;
  };
}

export async function dropDialogue({
  item,
  buildIdObject,
  targetFolderId,
  dialogueInFolder,
  toast,
  name,
  currentLanguage,
}: {
  item: DropDialogueItem;
  buildIdObject: BuildIdObject;
  targetFolderId: string;
  dialogueInFolder: ({
    variables,
  }: {
    variables: DropDialogueInFolderMutationVariables;
  }) => Promise<FetchResult<DropDialogueInFolderMutation>>;
  toast: any;
  name: string;
  currentLanguage: string;
}) {
  const dropVariables: DropDialogueInFolderMutationVariables = {
    ...buildIdObject,
    sourceDialogueId: item.id,
    targetFolderId,
  };

  try {
    await dialogueInFolder({ variables: dropVariables });
  } catch (error) {
    toast.error(error.message);
    return;
  }

  toast.success(`Dialogue '${item.title[currentLanguage]}' was moved to '${name}'`);
}

export async function dropFolder({
  folderInFolder,
  targetFolderId,
  item,
  buildIdObject,
  toast,
  name,
}: {
  folderInFolder: ({
    variables,
  }: {
    variables: DropFolderInFolderMutationVariables;
  }) => Promise<FetchResult<DropFolderInFolderMutation>>;
  targetFolderId: string;
  item: DropFolderItem;
  buildIdObject: BuildIdObject;
  toast: any;
  name: string;
}) {
  try {
    await folderInFolder({
      variables: {
        ...buildIdObject,
        sourceFolderId: item.id,
        targetFolderId,
      },
    });
  } catch (error) {
    toast.error(error.message);
    return;
  }

  toast.success(`Folder '${item.name}' was moved to '${name}'`);
}
