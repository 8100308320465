import { useApolloClient } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import removeFieldsContainingVariables from 'frontend/api/cacheHelpers/removeFieldsContainingVariables';
import { useBotOrSkill } from 'frontend/hooks';
import useMe from 'frontend/hooks/useMe';
import { buildItemTransferred } from 'frontend/routes/hooks/utils/buildItemTransferred';
import { dialogueCreated, dialogueDeleted, dialogueUpdated } from 'frontend/routes/hooks/utils/dialogues';
import { folderCreated, folderDeleted, folderUpdated } from 'frontend/routes/hooks/utils/folders';
import { onWorkspaceImport } from 'frontend/routes/hooks/utils/onWorkspaceImport';
import {
  entityCreated,
  entityDeleted,
  entityUpdated,
  samplesDroppedInDialogue,
} from 'frontend/routes/hooks/utils/samples';

export default function useBotLikeEvents(botLike?: { permissions: string[] }) {
  const client = useApolloClient();
  const { data } = useMe();
  const userId = data?.me?.id;
  const { buildIdObject, isBot, buildId } = useBotOrSkill({ ignoreNoBotOrSkill: true });
  const buildPermission = isBot ? 'view_templates' : 'read_skill_content';
  const hasBuildPermission = (botLike?.permissions ?? []).includes(buildPermission);

  const events = useMemo(
    () => ({
      'dialogue-created': dialogueCreated({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'dialogue-updated': dialogueUpdated({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'dialogue-deleted': dialogueDeleted({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'folder-created': folderCreated({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'folder-updated': folderUpdated({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'folder-deleted': folderDeleted({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'samples-dropped-in-dialogue': samplesDroppedInDialogue({
        buildIdObject: buildIdObject!,
        client,
        hasBuildPermission,
      }),
      'entity-created': entityCreated({ buildIdObject: buildIdObject!, client, hasBuildPermission, isBot }),
      'entity-updated': entityUpdated({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'entity-deleted': entityDeleted({ client, hasBuildPermission }),
      'build-item-transferred': buildItemTransferred({ buildIdObject: buildIdObject!, client, hasBuildPermission }),
      'content-import': onWorkspaceImport({
        buildIdObject: buildIdObject!,
        client,
        hasBuildPermission,
        userId: userId!,
      }),
    }),
    [buildIdObject, client, hasBuildPermission, isBot, userId],
  );

  useEffect(
    () => () => {
      // Clear skill / bot field
      const field = isBot ? 'bot' : 'skill';
      client.cache.modify({
        fields: {
          [field]: () => undefined,
        },
        // variables: { id: buildId },
      });

      // Clear other fields related to skill / bot
      removeFieldsContainingVariables(client, buildIdObject!);
    },
    [buildIdObject, buildId, isBot, client],
  );

  return events;
}
