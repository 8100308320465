import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import {
  CommentPageDocument,
  type CommentPagePaginatedType,
  type CommentSortType,
  type CommentsFilterFlagType,
  MutateCommentReadDocument,
} from 'frontend/api/generated';

import { CommentsContext } from '../context/commentsContext';

type MutateContextType = {
  level: string;
  levelId: string;
  category: string;
  sortFilter: CommentSortType;
  commentsFilter: CommentsFilterFlagType;
  pathname: string;
  archive?: boolean;
  pin?: boolean;
};

export default () => {
  const {
    pageDetails: { level, levelId, category },
    commentsFilter,
    sortFilter,
  } = useContext(CommentsContext);
  const { pathname } = window.location;
  const [mutateComment] = useMutation(MutateCommentReadDocument, {
    update: (cache, { data: comments }, options) => {
      if (!comments?.mutateCommentRead) return;
      const { mutateCommentRead } = comments;

      const { context } = options;
      const {
        level: mutateLevel,
        levelId: mutateLevelId,
        category: mutateCategory,
        sortFilter: mutateSortFilter,
        commentsFilter: mutateCommentsFilter,
        pathname: mutatePathname,
      } = context as MutateContextType;

      cache.updateQuery(
        {
          query: CommentPageDocument,
          variables: {
            level: mutateLevel as string,
            levelId: mutateLevelId as string,
            category: mutateCategory as string,
            sort: mutateSortFilter,
            paginationSize: 10,
            paginationPage: 1,
            path: mutatePathname,
            filterFlag: mutateCommentsFilter,
          },
        },
        (data) => {
          const currentComments = [...(data?.commentPage?.comments || [])];
          const commentIndex = currentComments.findIndex((comment) => comment.id === mutateCommentRead.id);
          currentComments[commentIndex] = mutateCommentRead;

          return {
            ...data,
            commentPage: {
              ...(data?.commentPage as CommentPagePaginatedType),
              comments: currentComments,
            },
          };
        },
      );
    },
  });

  const updateCommentRead = async ({ commentId, read }: { commentId: string; read: boolean }) =>
    mutateComment({
      variables: {
        commentId,
        read,
      },
      context: {
        level,
        levelId,
        category,
        sortFilter,
        pathname,
        commentsFilter,
      },
    });

  return { updateCommentRead };
};
