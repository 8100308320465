import cx from 'classnames';
import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-final-form';

import { ArrowLeft } from 'frontend/assets/icons';
import Button from 'frontend/components/Button';
import { Icon } from 'frontend/components/Icon/Icon';
import { useEventListener } from 'frontend/hooks';

import styles from './FilterDropdown.scss';
import { FilterContext } from '../../context/FilterContext';
import { FILTERS_LIST, type FilterStepType } from '../../utils/config';

const FilterDropdown = ({
  componentKey,
  shouldSubmitOnToggle,
  setCurrentActiveDropdown,
}: {
  componentKey: string;
  shouldSubmitOnToggle: boolean;
  setCurrentActiveDropdown: (val: any) => void;
}) => {
  const { change } = useForm();
  const { steps, setSteps } = useContext(FilterContext);
  const contentScrollRef = useRef<HTMLDivElement>(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  let ComponentToRender: React.ReactNode = null;
  let title: null | string = null;
  let canProceedForward = true;
  let foundStep: FilterStepType | null | undefined = null;

  const foundFilter = FILTERS_LIST.find((filterItem) => componentKey.startsWith(filterItem.filterKey));
  if (foundFilter?.steps) {
    const currentStep = steps?.[componentKey]?.currentStep || 0;

    foundStep = foundFilter.steps[currentStep];

    if (foundStep?.required) {
      canProceedForward = false;
      if (steps?.[componentKey]?.values[currentStep]?.length) {
        canProceedForward = true;
      }
    }

    if (currentStep < foundFilter.steps.length && foundStep) {
      ComponentToRender = foundStep.component(componentKey);
      title = foundStep.text;
    }
  }

  if (!ComponentToRender && foundFilter) {
    ComponentToRender = foundFilter?.component(componentKey, shouldSubmitOnToggle);
    title = foundFilter.text;
  }

  useEventListener(
    'scroll',
    (e) => {
      if (e.target.scrollTop > 0 && !hasScrolled) {
        setHasScrolled(true);
      } else if (e.target.scrollTop === 0 && hasScrolled) {
        setHasScrolled(false);
      }
    },
    contentScrollRef?.current?.parentElement as EventTarget,
  );

  return (
    <div className={styles.filterDropdown} ref={contentScrollRef}>
      <div className={cx(styles.filterDropdownHeader, { [styles.filterDropdownHeaderScrolled]: hasScrolled })}>
        <div className={styles.filterDropdownHeaderLeftContent}>
          <Icon
            onClick={() => {
              const currentStep = steps?.[componentKey]?.currentStep;
              if (currentStep) {
                if (currentStep > 0) {
                  const arrayCopy = [...(steps[componentKey]?.values || [])];
                  arrayCopy.splice(currentStep, 1);
                  setSteps({
                    ...steps,
                    [componentKey]: {
                      values: arrayCopy,
                      currentStep: currentStep - 1,
                    },
                  });
                  change(componentKey, undefined);
                }
              } else {
                if (steps?.[componentKey]) {
                  const copySteps = { ...steps };
                  delete copySteps[componentKey];
                  setSteps(copySteps);
                }
                setCurrentActiveDropdown((vals) => {
                  change(vals.value, undefined);
                  return null;
                });
              }
            }}
            component={ArrowLeft}
          />
        </div>
        <div className={styles.filterDropdownHeaderTitle}>{title}</div>
      </div>
      <div
        className={cx(styles.activeDropdownWrapper, { [styles.activeDropdownWithFooter]: foundStep?.selectMultiple })}
      >
        <div
          className={cx(styles.filterOverlayWrapper, {
            [styles.filterOverlayWrapperWithFooter]: foundStep?.selectMultiple,
          })}
          role="complementary"
        >
          {ComponentToRender || null}
          {foundStep?.selectMultiple && foundStep?.forwardText && (
            <div className={styles.filterDropdownFooter}>
              <Button
                title={!canProceedForward ? 'This step is required' : undefined}
                className={styles.filterDropdownNext}
                disabled={!canProceedForward}
                color="primary"
                onClick={() => {
                  setSteps({
                    ...steps,
                    [componentKey]: {
                      values: steps?.[componentKey]?.values || [],
                      currentStep: (steps?.[componentKey]?.currentStep || 0) + 1,
                    },
                  });
                }}
                text={foundStep?.forwardText}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;
