import { useQuery } from '@apollo/client';
import { formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { UserSecurityDocument } from 'frontend/api/generated';
import { Button, LoaderSwitch, Table } from 'frontend/components';
import Panel, { HorizontalRule } from 'frontend/components/Panel';
import { useModal } from 'frontend/features/Modals';

import styles from './ProfileSecurity.scss';
import UpdateOTPModal from '../../modals/UpdateOTPModal';
import UpdatePasswordModal from '../../modals/UpdatePasswordModal';

const AttemptTimeCell = ({ data }) => (
  <time dateTime={data} title={data.slice(0, -13)}>
    {formatDistanceToNow(data, { addSuffix: true })}
  </time>
);

AttemptTimeCell.propTypes = {
  data: PropTypes.string.isRequired,
};

const columns = [
  { key: 'time', render: AttemptTimeCell },
  { title: 'User agent', key: 'userAgent' },
  { title: 'IP address', key: 'ipAddress' },
];

const DAYS = 30;

const ProfileSecurity = () => {
  const { data, loading } = useQuery(UserSecurityDocument);

  const [showUpdatePasswordModal] = useModal(UpdatePasswordModal);
  const [showUpdateOTPModal] = useModal(UpdateOTPModal({ deleteOtp: false }));
  const [showDeleteOTPModal] = useModal(UpdateOTPModal({ deleteOtp: true }));

  const me = data?.me;
  const accessLog = me?.accessLog;

  const otpEnabled = me?.profile?.otpEnabled;
  const organizationOtpEnforced = me?.profile?.organizationOtpEnforced;

  const tableData = useMemo(
    () =>
      (me?.accessLog ?? []).map((logItem) => ({
        time: logItem.attemptTime,
        userAgent: logItem.userAgent,
        ipAddress: logItem.ipAddress,
      })),
    [me],
  );
  const hasData = Boolean(accessLog?.length);

  return (
    <Panel>
      <section className={styles.sectionWidthLimited}>
        <h3>Password</h3>
        <p>Change your account password.</p>
        <Button onClick={showUpdatePasswordModal} text="Change password" />
      </section>

      <HorizontalRule />
      <section className={styles.sectionWidthLimited}>
        <h3>One Time Password (OTP)</h3>
        <LoaderSwitch loading={loading} size="large">
          <section>
            <p>{otpEnabled ? 'Update' : 'Create'} your One Time Password</p>
            <Button onClick={showUpdateOTPModal} text={`${otpEnabled ? 'Update' : 'Create'} OTP`} />
          </section>
          {!organizationOtpEnforced && otpEnabled && (
            <section>
              <p>Delete your One Time Password</p>
              <Button color="warning" onClick={showDeleteOTPModal} text="Delete OTP" />
            </section>
          )}
        </LoaderSwitch>
        <p>
          Using OTP adds an extra layer of security to your account. You will need to enter a one-time password (OTP) in
          addition to your regular password when logging in. <br />
          The OTP is generated by an app on your phone, such as Microsoft Authenticator or Google Authenticator. To set
          it up, install a TOTP app, scan the provided QR code, and follow the instructions. Once configured, you will
          use the app to generate a code each time you log in.
        </p>
      </section>

      <HorizontalRule />
      <section>
        <h3>Access log</h3>
        <p className={styles.description}>Logins for your account in the last {DAYS} days.</p>
        <LoaderSwitch loading={loading} size="large">
          {hasData && <Table className={styles.table} columns={columns} data={tableData as any} nested />}
          {!hasData && <p>No logins found</p>}
        </LoaderSwitch>
      </section>
    </Panel>
  );
};
export default ProfileSecurity;
