import { get, isEmpty, isEqual, isPlainObject, mergeWith, pick } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import type { DialogueType } from 'frontend/api/generated';
import { formStatesAreEqual } from 'frontend/utils';

import { dialogueInputFields, handleButtonsAndImages, modDialogueInputFields } from '../utils';

type Values = Record<string, unknown>;

/*
If a dialogue gets external changes (i.e. someone else has modified the dialogue,
the user updates entities, etc) while also having changes in the current form, the
external changes are stored in state and merged with current changes on save.
*/
function mergeAndReplaceArrays(object1: Values, object2: Values): Values {
  return mergeWith({}, object1, object2, (field1: unknown, field2: unknown) => {
    if (Array.isArray(field1) && Array.isArray(field2)) {
      return field2;
    }
    return undefined;
  });
}

const relatedFields = ['title', 'samples', 'labels', 'replies', 'outputSlots', 'buttons', 'imageCarousels'] as const;

const relatedModFields = ['modSamples', 'modReplies', 'modButtons', 'modImageCarousels'] as const;

function getRelevantFields(
  isSubscriptionDialogue: boolean,
): typeof modDialogueInputFields | typeof dialogueInputFields {
  return isSubscriptionDialogue ? modDialogueInputFields : dialogueInputFields;
}

type GetChangedFieldsFunction = (currentLanguage: string, initialValues: Values, values: Values) => string[];

function useGetChangedFields(isSubscriptionDialogue: boolean): GetChangedFieldsFunction {
  const getChangedFields = useCallback(
    (currentLanguage: string, initialValues: Values, values: Values): string[] => {
      const fields = getRelevantFields(isSubscriptionDialogue);

      const relevantFieldPaths = fields.reduce((result: string[], fieldName: string) => {
        if (!(fieldName in values) && !(fieldName in initialValues)) {
          return result;
        }
        if (isPlainObject(values[fieldName])) {
          return [...result, `${fieldName}.${currentLanguage}`];
        }
        return [...result, fieldName];
      }, []);

      return relevantFieldPaths.filter((fieldPath) => !isEqual(get(values, fieldPath), get(initialValues, fieldPath)));
    },
    [isSubscriptionDialogue],
  );

  return getChangedFields;
}

type GetFieldsFunction = (values: Values) => {
  imageCarousels: unknown;
  buttons: unknown[];
  modImageCarousel?: unknown;
  modButtons?: unknown[];
};

function useGetFields(currentLanguage: string, isSubscriptionDialogue: boolean): GetFieldsFunction {
  const getFields = useCallback(
    (values: Values) => {
      let modFields = {};

      if (isSubscriptionDialogue) {
        modFields = {
          mod: handleButtonsAndImages(
            currentLanguage,
            pick(values.mod, [...modDialogueInputFields, ...relatedModFields]),
          ),
        };
      }

      return handleButtonsAndImages(currentLanguage, {
        ...pick(values, [...dialogueInputFields, ...relatedFields]),
        ...modFields,
      });
    },
    [currentLanguage, isSubscriptionDialogue],
  );

  return getFields;
}

function useGetValues(isSubscriptionDialogue: boolean): (values: Values) => Values {
  const getValues = useCallback(
    (values: { mod?: Values } & Values): Values => (isSubscriptionDialogue ? (values.mod ?? {}) : values),
    [isSubscriptionDialogue],
  );
  return getValues;
}

export default function useExternalChanges({
  data,
  hasChanges,
  initialValues,
  updateInitialValues,
  selectedLanguage,
  isSubscriptionDialogue = false,
}: {
  data: Values & { dialogue: DialogueType };
  hasChanges: unknown;
  initialValues: Values;
  updateInitialValues: (...args) => unknown;
  selectedLanguage: string;
  isSubscriptionDialogue: boolean;
}) {
  const [blockedExternalChanges, setBlockedExternalChanges] = useState<Values>();

  const getFields = useGetFields(selectedLanguage, isSubscriptionDialogue);
  const getChangedFields = useGetChangedFields(isSubscriptionDialogue);
  const getValues = useGetValues(isSubscriptionDialogue);

  const mergeWithExternalChanges = useCallback(
    (values: Values) => {
      if (isEmpty(blockedExternalChanges)) {
        return values;
      }

      const fields = getRelevantFields(isSubscriptionDialogue);
      const changedFields = getChangedFields(selectedLanguage, getValues(initialValues), values);

      // It's important to use deep merge or changes in one language will overwrite those in another
      return mergeAndReplaceArrays(pick(getValues(blockedExternalChanges), fields), pick(values, changedFields));
    },
    [blockedExternalChanges, selectedLanguage, getChangedFields, getValues, initialValues, isSubscriptionDialogue],
  );

  useEffect(() => {
    if (isEmpty(initialValues) || isEmpty(data)) {
      return;
    }

    const dialogueData = getFields(data.dialogue);
    const hasExternalChanges = !formStatesAreEqual(getFields(initialValues), dialogueData);

    // No external changes; no action necessary
    if (!hasExternalChanges) {
      return;
    }

    // No changes in current state; apply external changes and bail
    if (!hasChanges) {
      updateInitialValues(data, selectedLanguage);
      setBlockedExternalChanges({});
      return;
    }

    // External changes haven't changed since last iteration; no action necessary
    if (formStatesAreEqual(blockedExternalChanges, dialogueData)) {
      return;
    }

    // Store external changes to be merged with current changes on save
    setBlockedExternalChanges(dialogueData);
  }, [
    blockedExternalChanges,
    selectedLanguage,
    data,
    getFields,
    hasChanges,
    initialValues,
    isSubscriptionDialogue,
    updateInitialValues,
  ]);

  return { mergeWithExternalChanges, reset: setBlockedExternalChanges };
}
