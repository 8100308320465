import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';

import { BotDocument } from 'frontend/api/generated';

import styles from './CommentsWrapper.scss';
import { CommentsContext } from '../../context/commentsContext';
import useCommentsQuery from '../../hooks/useCommentsQuery';
import { getPath } from '../../utils/helpers';
import CommentsHeader from '../CommentsHeader/CommentsHeader';
import CommentsInput from '../CommentsInput/CommentsInput';
import { CommentsList } from '../CommentsList/CommentsList';

const CommentsWrapper = () => {
  const { pageDetails, isOrganization, filterType, commentsFilter } = useContext(CommentsContext);
  const { levelId } = pageDetails;

  const { data: botData } = useQuery(BotDocument, {
    variables: {
      botId: levelId!,
    },
    skip: isOrganization || !levelId,
  });

  const { data } = useCommentsQuery();

  return (
    <div className={styles.commentsWrapper}>
      <CommentsHeader />
      <div className={styles.commentsContainer}>
        <div className={styles.commentsContainerInfo}>
          {data?.commentPage?.count} Comments in -{' '}
          {data?.commentPage?.pageDetails.path &&
            getPath(filterType, data.commentPage.pageDetails, botData?.bot.organization.id)}
        </div>
        {commentsFilter !== 'ARCHIVED' && <CommentsInput />}
        <CommentsList />
      </div>
    </div>
  );
};

export default CommentsWrapper;
