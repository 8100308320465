import { useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { Loader, Panel } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './Beta.scss';
import BetaField from './BetaField';
import EnableBetaFeature from '../../modals/EnableBetaFeature';

export default function BetaForm(): React.JSX.Element {
  const { change } = useForm();
  const { values } = useFormState();
  const [showBetaModal] = useModal(EnableBetaFeature);
  const { botId } = useParams();
  const { hasBotPerms, loading: loadingPermissions } = useMyPermissions({ botId });

  const handleKindlyAIPlusChange = (name: string, value: boolean) => {
    change('bot.slotsEnabled', false);
    change('bot.kindlyEntitiesEnabled', false);
    change(name, value);
  };
  const canManageKindlyEntities = hasBotPerms('manage_kindly_entities');
  const canManageSlots = hasBotPerms('manage_slots');
  const canManageFeatureFlags = hasBotPerms('manage_feature_flags');

  const isIntentWorkspace = values.bot.intentMatchEnabled;

  if (loadingPermissions) {
    return <Loader />;
  }

  return (
    <Panel>
      <h3 className={`${styles.sectionTitle} m-b-3`}>Beta settings</h3>
      {!canManageKindlyEntities && !canManageSlots && !canManageFeatureFlags && (
        <p>There are currently no beta settings available.</p>
      )}
      {!isIntentWorkspace && (
        <>
          {canManageKindlyEntities && (
            <BetaField
              change={change}
              values={values}
              path="bot.kindlyEntitiesEnabled"
              showBetaModal={showBetaModal}
              featureTitle="Kindly entities"
            />
          )}
          {canManageSlots && (
            <BetaField
              change={change}
              values={values}
              path="bot.slotsEnabled"
              showBetaModal={showBetaModal}
              featureTitle="Slots"
            />
          )}
        </>
      )}
      {canManageFeatureFlags && (
        <>
          <BetaField
            change={change}
            values={values}
            path="bot.nlpSettings.crossLanguagePrediction"
            description="Enabling this will allow your bot to use active samples from other languages to improve dialogue prediction in the chat language (if Zodiac is off). If Zodiac is on, it will only use active samples from the primary language."
            showBetaModal={showBetaModal}
            featureTitle="Cross language prediction"
          />
          <BetaField
            change={change}
            values={values}
            path="bot.globalIconsEnabled"
            showBetaModal={showBetaModal}
            featureTitle="Global icons"
          />
          <BetaField
            change={change}
            values={values}
            path="bot.whitelabelEnabled"
            description='Enabling this will remove the "Kindly" branding inside Kindly-Chat'
            showBetaModal={showBetaModal}
            featureTitle="Whitelabel"
          />
          <BetaField
            change={change}
            values={values}
            path="bot.kindlyGptSettingsEnabled"
            description="This will enable Kindly GPT settings in the Connect page"
            showBetaModal={showBetaModal}
            featureTitle="Kindly GPT (GUI)"
          />
          <BetaField
            change={handleKindlyAIPlusChange}
            values={values}
            path="bot.intentMatchEnabled"
            description="<p>Enabling this feature will:<ul><li>delete permanently all sample dialogues from our database</li><li>remove the option to add, view, edit and delete samples</li><li>disable permanently Slots, Entities and Skills</li></ul>If you want to convert existing samples dialogues to intents dialogues, consider cloning the workspace to intents instead.</p>"
            showBetaModal={showBetaModal}
            featureTitle="Kindly AI Plus"
            oneTimeOnly
          />
          {isIntentWorkspace && (
            <BetaField
              change={change}
              values={values}
              path="bot.insightsOverviewEnabled"
              description="This will enable Insights Overview"
              showBetaModal={showBetaModal}
              featureTitle="Insights Overview"
            />
          )}
          <BetaField
            change={change}
            values={values}
            path="bot.handoverIdleTimeoutEnabled"
            description="Enabling this will remove users from handover / queue after 2 minutes of inactivity"
            showBetaModal={showBetaModal}
            featureTitle="Handover / Queue idle timeout"
          />
        </>
      )}
    </Panel>
  );
}
