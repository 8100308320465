import cx from 'classnames';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';

import { Cloud } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { shouldShowComments } from 'frontend/features/Comments/utils/helpers';
import { useModal } from 'frontend/features/Modals';
import { selectComments } from 'frontend/state/dux/comments';
import { selectNavbarHeight } from 'frontend/state/dux/navbar';
import { useAppSelector } from 'frontend/state/hooks';

import styles from './PageBar.scss';
import ResetFormModal from './ResetFormModal';

const SLIM_NAVBAR_HEIGHT = 67;

interface PageBarProps {
  children: React.ReactNode;
  className?: string;
  noShadow?: boolean;
}

const PageBar = ({ children, className, noShadow }: PageBarProps) => {
  const showComments = shouldShowComments(window.location.pathname);
  const { open } = useAppSelector(selectComments);
  const isNavbarStartingSlim = useSelector(selectNavbarHeight) === SLIM_NAVBAR_HEIGHT;
  const classNames = cx(className, styles.pageBar, {
    [styles.noShadow]: noShadow,
    [styles.responsiveSlimNav]: isNavbarStartingSlim,
    [styles.fatNav]: !isNavbarStartingSlim,
    [styles.commentsOpen]: open && showComments,
  });

  return <div className={classNames}>{children}</div>;
};

interface FormButtonsProps {
  isSubmitting?: boolean;
  hasChanges?: boolean;
  hasChangesText?: string;
  noChangesText?: string;
  className?: string;
}

const FormButtons = ({
  isSubmitting,
  hasChanges,
  hasChangesText = 'Save',
  noChangesText = 'Saved',
  className,
}: FormButtonsProps) => {
  const { submit, reset, getState } = useForm();
  const { dirty, submitting } = useFormState();
  const [showResetModal] = useModal(ResetFormModal);

  // Use either the supplied prop or final-form's parameter
  const formHasChanges = hasChanges ?? dirty;
  const formIsSubmitting = isSubmitting ?? submitting;

  const handleCancel = useCallback(() => {
    const { initialValues } = getState();
    reset(initialValues);
  }, [getState, reset]);

  const onClickReset = useCallback(() => showResetModal({ handleCancel }), [handleCancel, showResetModal]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    if (!formHasChanges || formIsSubmitting) return;
    submit();
  };

  useHotkeys('mod+s', handleSubmit, [formHasChanges, formIsSubmitting, submit], {
    enableOnFormTags: ['input', 'textarea', 'select'],
    enableOnContentEditable: true, // draftJS is contentEditable
  });

  return (
    <div className={styles.formButtonsContainer}>
      {formHasChanges && (
        <Button color="white" onClick={onClickReset}>
          Clear changes
        </Button>
      )}
      <Button
        disabled={!formHasChanges}
        className={cx(styles.saveButton, className)}
        onClick={handleSubmit}
        color={formHasChanges ? 'primary' : 'secondary'}
        isSubmitting={formIsSubmitting}
        icon={formHasChanges ? Cloud : undefined}
        text={formHasChanges ? hasChangesText : noChangesText}
      />
    </div>
  );
};

PageBar.FormButtons = FormButtons;

export default PageBar;
