import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { selectComments, toggleComments } from 'frontend/state/dux/comments';
import { useAppDispatch, useAppSelector } from 'frontend/state/hooks';

import CommentsWrapper from './components/CommentsWrapper/CommentsWrapper';
import CommentsProvider from './context/commentsContext';

const Comments = () => {
  const { open } = useAppSelector(selectComments);

  const dispatch = useAppDispatch();
  useHotkeys(
    'meta+ctrl+c',
    () => {
      dispatch(toggleComments());
    },
    [],
  );

  return open ? (
    <CommentsProvider>
      <CommentsWrapper />
    </CommentsProvider>
  ) : null;
};

export default Comments;
