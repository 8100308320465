import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FolderType } from 'frontend/api/generated';
import { Folder, FolderPerson, FolderPlus, KindlyBlob, Trash } from 'frontend/assets/icons';
import type { MenuOverlayOptions } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES } from 'frontend/constants';
import CreateFolder from 'frontend/features/Library/modals/CreateFolder/CreateFolder';
import DeleteFolder from 'frontend/features/Library/modals/DeleteFolder/DeleteFolder';
import TransferFolder from 'frontend/features/Library/modals/TransferFolder/TransferFolder';
import UpdateFolder from 'frontend/features/Library/modals/UpdateFolder/UpdateFolder';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

export function useTopicContextMenu({
  topic,
  botOrSkillParams,
}: {
  topic: Partial<FolderType>;
  botOrSkillParams: BotOrSkill;
}): {
  actions: MenuOverlayOptions;
  contextMenuId: string;
} {
  const navigate = useNavigate();
  const { buildType, buildId } = useBotOrSkill();
  const [showTransferFolderModal] = useModal(TransferFolder);
  const [showCreateFolder] = useModal(CreateFolder);
  const [showUpdateFolder] = useModal(UpdateFolder);
  const [showDeleteFolder] = useModal(DeleteFolder);

  const actions = useMemo(() => {
    const target = `new?topicId=${topic.id}`;
    const newDialogueUrl = getBuildUrl({ buildType, buildId, dialogueType: DIALOGUE_TYPES.REGULAR, target });

    const actionsArray: MenuOverlayOptions = [
      {
        text: 'New dialogue',
        icon: KindlyBlob,
        onClick: () => navigate(newDialogueUrl),
      },
      {
        text: 'New folder',
        icon: FolderPlus,
        onClick: () => showCreateFolder({ parentTopicId: topic.id, buildIdObject: botOrSkillParams.buildIdObject }),
      },
      'separator',
      {
        text: 'Edit folder',
        icon: Folder,
        onClick: () => showUpdateFolder({ topic, buildIdObject: botOrSkillParams.buildIdObject }),
      },
      {
        text: 'Transfer folder',
        icon: FolderPerson,
        onClick: () => showTransferFolderModal({ topic, botOrSkillParams }),
      },
      'separator',
      {
        text: 'Delete folder',
        icon: Trash,
        onClick: () => showDeleteFolder({ topic, botOrSkillParams }),
        color: 'red',
      },
    ];

    return actionsArray;
  }, [
    topic,
    botOrSkillParams,
    buildType,
    buildId,
    navigate,
    showCreateFolder,
    showDeleteFolder,
    showUpdateFolder,
    showTransferFolderModal,
  ]);

  return { actions, contextMenuId: `library-context-menu` };
}
