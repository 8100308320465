import type { ApolloClient } from '@apollo/client';
import { get } from 'lodash';

import { DialogueListDocument } from 'frontend/api/generated';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { GetDialogue } from 'frontend/features/Build/graphql';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';
import { updateSkillsubscriptionDialogues } from 'frontend/routes/hooks/utils/skills';
import { fetchIfNecessary } from 'frontend/utils';

const fetchPolicy = 'network-only';

function updateAllDialoguesQuery({
  buildIdObject,
  client,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
}) {
  fetchIfNecessary({
    client,
    query: DialogueListDocument,
    variables: { ...buildIdObject, includeSubscribedSkills: true },
  });
  fetchIfNecessary({
    client,
    query: DialogueListDocument,
    variables: { ...buildIdObject, dialogueType: DIALOGUE_TYPES.SAMPLES },
  });
}

export function dialogueUpdated({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return ({ id: dialogueId, skill }: { id: string; skill: string }) => {
    if (!hasBuildPermission) {
      return;
    }

    client.query({
      query: GetDialogue,
      variables: { skillId: skill, ...buildIdObject, dialogueId },
      fetchPolicy,
    });
    updateAllDialoguesQuery({ client, buildIdObject });
  };
}

export function dialogueCreated({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return async ({ id: dialogueId, skill }: { id: string; skill: string }) => {
    if (!hasBuildPermission) {
      return;
    }

    const variables = { ...buildIdObject, dialogueId };
    const result = await client.query({ query: GetDialogue, variables, fetchPolicy });
    const dialogue = get(result, 'data.dialogue', {});

    if (skill) {
      updateSkillsubscriptionDialogues({ client, skillId: skill, buildIdObject, dialogue });
    }
    updateAllDialoguesQuery({ client, buildIdObject });
  };
}

export function dialogueDeleted({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return async ({ skill }: { skill: string }) => {
    if (!hasBuildPermission) {
      return;
    }

    if (skill) {
      updateSkillsubscriptionDialogues({ client, skillId: skill, buildIdObject, dialogue: undefined });
    }

    updateAllDialoguesQuery({ client, buildIdObject });
  };
}
