import type { ApolloClient } from '@apollo/client';

import { ENTITIES } from 'frontend/features/Entities/queries';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';
import { fetchIfNecessary } from 'frontend/utils';

export function buildItemTransferred({
  buildIdObject,
  client,
  hasBuildPermission,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  hasBuildPermission: boolean;
}) {
  return () => {
    if (!hasBuildPermission) {
      return;
    }

    fetchIfNecessary({ query: ENTITIES, variables: buildIdObject, client });
  };
}
