import { captureException } from '@sentry/browser';
import { format, subDays } from 'date-fns';
import { isToday } from 'date-fns/isToday';
import { isYesterday } from 'date-fns/isYesterday';

import type { CommentsFilterType, LevelType, PageCategory, PageDetailsType } from 'frontend/api/generated';

import { PAGE_CATEGORIES, PAGE_LEVEL } from './constants';
import type { CommentPathType } from './types';

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  let formattedDate;
  if (isToday(date)) {
    formattedDate = 'Today';
  } else if (isYesterday(date)) {
    formattedDate = 'Yesterday';
  } else if (date > subDays(new Date(), 7)) {
    formattedDate = format(date, 'EEEE'); // Day of the week
  } else {
    formattedDate = format(date, 'MM/dd/yyyy'); // Date
  }
  return `${formattedDate} - ${format(date, 'HH:mm')}`; // Add time
};

export const getPath = (filterType: CommentsFilterType, path: PageDetailsType, organizationId?: string) => {
  const { pathname } = window.location;
  switch (filterType) {
    case 'HERE':
      if (pathname.startsWith(`/workspace/${path.levelId}/`)) {
        return pathname.replace(`/workspace/${path.levelId}/`, '');
      }
      return window.location.pathname;
    case 'WORKSPACE':
      return `/${path.level.toLowerCase()}/${path.levelId}/`;
    case 'ALL':
      return organizationId ? `/organization/${organizationId}/` : 'organization';
    default:
      return 'Comments here';
  }
};

export const shouldShowComments = (pathname: string): boolean => {
  if (!pathname) throw new Error('Pathname was not provided. Comments needs pathname to function.');
  const [level, levelId, category] = pathname
    .split('/')
    .filter(Boolean)
    .map((item) => (typeof item === 'string' ? item.toUpperCase() : item)) as [LevelType, string, PageCategory];

  return !!(PAGE_LEVEL.includes(level) && PAGE_CATEGORIES.includes(category) && levelId);
};

export const getCommentPath = (pathname: string): CommentPathType | undefined => {
  if (!pathname) throw new Error('Pathname was not provided. Comments needs pathname to function.');
  const [level, levelId, category] = pathname
    .split('/')
    .filter(Boolean)
    .map((item) => (typeof item === 'string' ? item.toUpperCase() : item)) as [LevelType, string, PageCategory];

  if (!PAGE_LEVEL.includes(level)) {
    captureException(new Error('Comments Page. Invalid Page level provided'));
    return undefined;
  }
  if (!PAGE_CATEGORIES.includes(category)) {
    captureException(Error('Comments Page. Invalid category provided in pathname.'));
    return undefined;
  }

  if (!levelId) {
    captureException(new Error('Comments Page. No levelId provided.'));
    return undefined;
  }

  return {
    level,
    levelId,
    category,
  };
};
