import { useMutation, useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import {
  ScraperConfigDocument,
  type ScraperConfigType,
  UpdateOrCreateScraperConfigDocument,
} from 'frontend/api/generated';
import { GuardUnsaved, InfoBox, LoaderSwitch, PageBar } from 'frontend/components';
import ConnectDetails from 'frontend/features/Connect/components/ConnectDetails/ConnectDetails';
import { SCRAPE_WEB_CONTENT_MIN_CHAR_LENGTH } from 'frontend/features/Connect/constants';
import { LanguagePicker } from 'frontend/features/Library/components';
import { useBotOrSkill, useCurrentLanguage, useLanguages, useToast } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import styles from './KindlyGPTSource.scss';
import kindlyGptSourcesConfig from '../../config/kindlyGptSourcesConfig';
import KindlyGPTUrls from '../KindlyGPTUrls/KindlyGPTUrls';

const getInitialValues = (scraperConfig?: ScraperConfigType | null) => {
  if (!scraperConfig) {
    return {
      isActive: false,
      autoScrape: false,
      urlScraperType: 'ZENROWS',
      allowHash: false,
      urlsToScrape: [],
      charLimForEmptyPages: SCRAPE_WEB_CONTENT_MIN_CHAR_LENGTH,
    };
  }
  return scraperConfig;
};

const KindlyGPTSource = () => {
  const [{ currentLanguage }] = useCurrentLanguage();
  const { sourceId } = useParams();

  const { buildIdObject, botId, buildType } = useBotOrSkill();

  const { languages } = useLanguages({ isBot: true, buildIdObject, buildType } as BotOrSkill);

  const { data, loading } = useQuery(ScraperConfigDocument, {
    variables: { languageCode: currentLanguage, botId: botId as string },
    skip: !botId,
  });

  const toast = useToast();

  const allowsHash = Boolean(data?.scraperConfig?.allowHash);

  const [updateOrCreateScraperConfig] = useMutation(UpdateOrCreateScraperConfigDocument, {
    update(cache, { data: updatedOrCreatedData }) {
      cache.writeQuery({
        query: ScraperConfigDocument,
        variables: { languageCode: currentLanguage, botId: botId as string },
        data: {
          scraperConfig: updatedOrCreatedData?.updateOrCreateConfig,
        },
      });
    },
  });

  const onSubmit = async (vals) => {
    const copyVals = { ...vals };

    try {
      await updateOrCreateScraperConfig({
        variables: {
          languageCode: currentLanguage,
          botId: botId as string,
          ...copyVals,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const source = kindlyGptSourcesConfig.find(({ id }) => id === sourceId);

  return (
    <div className={styles.scraperWrapper}>
      <LoaderSwitch loading={loading}>
        <Form
          initialValues={getInitialValues(data?.scraperConfig)}
          initialValuesEqual={isEqual}
          onSubmit={onSubmit}
          render={({ handleSubmit, values, initialValues }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <GuardUnsaved />
              <ConnectDetails
                headerContent={
                  <div className={styles.formButtonsContainer}>
                    <PageBar.FormButtons hasChanges={!isEqual(values, initialValues)} />
                  </div>
                }
                backButtonTitle="Kindly GPT"
              >
                <div>
                  {source && (
                    <div className={styles.scraperInfo}>
                      <div className={styles.scraperImgAndLanguage}>
                        <img src={source.logoUrl as string} alt={`${source.name} alt`} />
                        <div>
                          {languages.length && <LanguagePicker languages={languages} supportVariants={false} />}
                        </div>
                      </div>
                      <div className={styles.scraperTitle}>{source.name}</div>
                      <div className={styles.scraperDescription}>
                        {source.description
                          ?.split('\n')
                          .map((string) => <p key={`description-${string.length}`}>{string}</p>)}
                      </div>
                      <div className={styles.scraperReadMore}>
                        Read more details on how it works on{' '}
                        <a href="https://help.kindly.ai/kindly-gpt" target="_blank" rel="noreferrer">
                          help.kindly.ai/kindly-gpt
                        </a>
                        .
                      </div>
                      <InfoBox title="Warning" level="info" className={styles.warning}>
                        <p>
                          If your workspace does not have any traffic for the last 60 days, it will be automatically
                          turned off.
                        </p>
                      </InfoBox>
                    </div>
                  )}
                </div>
                <KindlyGPTUrls allowHash={allowsHash} />
              </ConnectDetails>
            </form>
          )}
        />
      </LoaderSwitch>
    </div>
  );
};

export default KindlyGPTSource;
