const dialogueInputFields = [
  'advancedOptions',
  'buttonsDisplayOrientation',
  'dialogueFallback',
  'dialogueType',
  'eventMessage',
  'form',
  'intent',
  'isActive',
  'keywords',
  'patternKey',
  'patterns',
  'slug',
  'videoSources',
  'webhookUrls',
] as const;

export default dialogueInputFields;
