import { capitalize, orderBy } from 'lodash';
import { useCallback } from 'react';

import type { MessageType } from 'frontend/api/generated';
import type { LibraryDialogue } from 'frontend/features/Library/components/DialogueItem/DialogueItem';
import LibraryFolder from 'frontend/features/Library/components/LibraryFolder/LibraryFolder';
import { useToggle } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

import { systemDialogueMessageTypes } from '../../constants';
import { useIndentationStyle } from '../../hooks';
import { getFolderIconClassnames } from '../../utils';
import DialogueList from '../DialogueList';

function messageTypeSorting({ messageType }: { messageType: MessageType }): number {
  switch (messageType) {
    case systemDialogueMessageTypes.REQUEST:
    case systemDialogueMessageTypes.DELETE_CHAT:
    case systemDialogueMessageTypes.REPLY:
      return 0;
    case systemDialogueMessageTypes.CLOSED:
    case systemDialogueMessageTypes.FALLBACK:
      return 1;
    case systemDialogueMessageTypes.CANCEL:
      return 2;
    case systemDialogueMessageTypes.WAITING_FALLBACK:
      return 3;
    case systemDialogueMessageTypes.START:
      return 4;
    case systemDialogueMessageTypes.END:
      return 5;
    default:
      return 6;
  }
}

type Props = {
  category: string;
  dialogues: LibraryDialogue[];
  selectedLanguage: string;
  buildIdObject: BuildIdObject;
  indentationLevel: number;
};

function InnerFolder({ category, dialogues, selectedLanguage, buildIdObject, indentationLevel }: Props) {
  const [open, toggleOpen] = useToggle(true);
  const indentationStyle = useIndentationStyle(indentationLevel);
  const sorting = useCallback((dialogueList) => orderBy(dialogueList, messageTypeSorting), []);
  const titleClassName = getFolderIconClassnames(open);
  // FIXME(legacy-takeover): Make backend use "HANDOVER" instead of "TAKEOVER"
  const categoryRenameHandover = category === 'TAKEOVER' ? 'HANDOVER' : category;

  return (
    <>
      <LibraryFolder toggleOpen={toggleOpen}>
        <span className={titleClassName} style={indentationStyle}>
          {capitalize(categoryRenameHandover)}
        </span>
      </LibraryFolder>
      {open && (
        <DialogueList
          dialogues={dialogues}
          selectedLanguage={selectedLanguage}
          buildIdObject={buildIdObject}
          sorting={sorting}
          indentationLevel={indentationLevel + 1}
        />
      )}
    </>
  );
}

export default InnerFolder;
