import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { removeListItemById } from 'frontend/api/cacheHelpers';
import { DeleteChatDocument } from 'frontend/api/generated';
import getChatPath from 'frontend/features/Insights/utils/getChatPath';
import { useToast } from 'frontend/hooks';

type UseDeleteChatType = {
  nextChatId: string;
  botId?: string;
  chatId?: string;
};

export default ({ nextChatId, botId, chatId }: UseDeleteChatType) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [deleteChat] = useMutation(DeleteChatDocument, {
    variables: {
      botId: botId!,
      chatId: chatId!,
    },
    update: (cache) => {
      cache.modify({
        id: `ChatsType:chats-${botId}`,
        fields: { chats: removeListItemById(chatId) as any },
      });
      toast.success('Chat deleted');
      navigate(`${getChatPath(`${nextChatId}`, { botId })}${window.location.search}`);
    },
  });

  return deleteChat;
};
