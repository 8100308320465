import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'frontend/components';
import useMe from 'frontend/hooks/useMe';
import { logout } from 'frontend/state/dux/auth';

import styles from '../../Invitation.scss';
import { InvitationType } from '../../propTypes';
import ContentBox from '../ContentBox';
import Info from '../Info';

const WrongUser = ({ invitation, goToLogin }) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { data, loading } = useMe();
  const currentEmail = data?.me?.username;

  const onClick = useCallback(async () => {
    await dispatch(logout(client));
    goToLogin();
  }, [dispatch, client, goToLogin]);

  if (loading) {
    return null;
  }

  return (
    <ContentBox>
      <Info invitation={invitation} />
      <br />
      <p className={styles.description}>
        This invitation is for {invitation.email}, while you are logged in as {currentEmail}.
      </p>
      <br />
      <Button onClick={onClick} color="primary">
        Log in as {invitation.email}
      </Button>
    </ContentBox>
  );
};

WrongUser.propTypes = {
  invitation: InvitationType.isRequired,
  goToLogin: PropTypes.func.isRequired,
};

export default WrongUser;
