import { type DocumentNode, useQuery } from '@apollo/client';
import { pick } from 'lodash';

import { ensureArray } from 'frontend/utils';

interface Props<TQuery, TObject> {
  query: TQuery;
  variables: any;
  pageGetter: (data: any) => {
    pages: number;
    objects: TObject[];
  };
  extraFields?: string | string[];
  pageNumber?: number;
}

export default <TQuery extends DocumentNode, TObject>({
  query,
  variables,
  pageGetter,
  extraFields = [],
}: Props<TQuery, TObject>) => {
  const { data, networkStatus } = useQuery<TQuery>(query, { variables: { ...variables } });

  const pageData = data ? pageGetter(data) : undefined;
  const objects = pageData?.objects;
  const pages = pageData?.pages;
  const extra = pick(data, ensureArray(extraFields));

  return {
    data,
    objects,
    pages,
    loading: networkStatus === 1,
    loadingNextPage: networkStatus === 2,
    extra,
  };
};
