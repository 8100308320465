import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { DropDialogueInFolderDocument, DropFolderInFolderDocument } from 'frontend/api/generated';
import type { DropDialogueItem, DropFolderItem } from 'frontend/features/Library/components/FolderItem/utils';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { useCurrentLanguage, useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

const TARGET_ROOT = 'ROOT';

export function useDropInFolder({
  buildIdObject,
  setShowLoader,
}: {
  buildIdObject: BuildIdObject;
  setShowLoader: CallableFunction;
}) {
  const [{ currentLanguage }] = useCurrentLanguage();
  const toast = useToast();
  const [dialogueInFolder] = useMutation(DropDialogueInFolderDocument, REFETCH_LIBRARY);
  const [folderInFolder] = useMutation(DropFolderInFolderDocument, REFETCH_LIBRARY);

  const drop = useCallback(
    async (item: DropDialogueItem | DropFolderItem) => {
      setShowLoader(true);
      try {
        if (item.type === 'DIALOGUE') {
          try {
            await dialogueInFolder({
              variables: {
                ...buildIdObject,
                sourceDialogueId: item.id,
                targetFolderId: TARGET_ROOT,
              },
            });
          } catch (error) {
            toast.error(error.message);
            return;
          }

          toast.success(`Dialogue '${item.title[currentLanguage]}' was moved to root level`);
        } else if (item.type === 'FOLDER') {
          try {
            await folderInFolder({
              variables: {
                ...buildIdObject,
                sourceFolderId: item.id,
                targetFolderId: TARGET_ROOT,
              },
            });
          } catch (error) {
            toast.error(error.message);
            return;
          }

          toast.success(`Folder '${item.name}' was moved to root level`);
        }
      } finally {
        setShowLoader(false);
      }
    },
    [buildIdObject, currentLanguage, dialogueInFolder, setShowLoader, toast, folderInFolder],
  );

  return { drop };
}
