/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import {
  type ElementType,
  type ForwardRefExoticComponent,
  type ReactElement,
  type RefAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useEventListener, useOnEscape } from 'frontend/hooks';

import styles from './Dropdown.scss';
import type { ButtonProps } from '../Button/Button';
import type { TabButtonProps } from '../TabButton/TabButton';
import DropdownOverlay from './components/DropdownOverlay/DropdownOverlay';
import MenuOverlay from '../subcomponents/MenuOverlay/MenuOverlay';

interface OverlayProps {
  close: () => void;
}

export type DropdownPosition =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'right'
  | 'left'
  | 'bottom'
  | 'bottom-right'
  | 'bottom-left'
  | 'custom';

export type DropdownRefType = HTMLDivElement & {
  openDropdown: () => void;
  closeDropdown: () => void;
  isActive?: boolean;
};

export interface DropdownProps<C extends React.ElementType> {
  /** If true, displays the dropdown arrow. */
  arrow?: boolean;
  children?: React.ReactNode | null;
  'data-testid'?: string;
  /** HTML tag to use for the button triggering the dropdown menu. Default = 'button'. */
  element?: C;
  isDisabled?: boolean;
  /** Make the dropdown work with hover instead of click. */
  isHoverMode?: boolean;
  ignoreOutsideClick?: boolean;
  onClick?(): void;
  /** Event to listen to for closing the dropdown on outside click. @default 'click'. */
  outsideClick?: 'click' | 'pointerdown';
  /** Overlay component to render inside the dropdown menu. */
  overlay: ((props: OverlayProps) => React.ReactElement<OverlayProps>) | ReactElement<OverlayProps>;
  /** Class name for to the Overlay component. */
  overlayClassName?: string;
  /** When `position=custom`, it's the `left` position of the overlay. */
  overlayLeft?: string;
  /** When `position=custom`, it's the `top` position of the overlay. */
  overlayTop?: string;
  /** Position of the dropdown when opened. Default = 'bottom-right'. */
  position?: DropdownPosition;
  /** Stop propagation of click events, useful to not trigger sorting for example. */
  stopPropagation?: boolean;
  /** Dropdown menu inline styles. */
  style?: React.CSSProperties;
  /** Native tooltip (i.e. `title` HTML attribute) to show over the dropdown trigger. */
  title?: string;
  /** Class name for the trigger button. */
  triggerClassName?: string;
  /** Class name to apply to the button + dropdown menu wrapper. */
  wrapperClassName?: string;
  elementProps?: ButtonProps | TabButtonProps;
  /** Callback function for active status */
  onToggle?: (active) => void;
  onClose?: () => void;
  overlayMaxWidth?: 'parent' | number;
  mode?: 'automatic' | 'manual';
  keepOpenOnActionElementClick?: boolean;
}

export interface DropdownComponent
  extends ForwardRefExoticComponent<DropdownProps<ElementType> & RefAttributes<HTMLDivElement>> {
  MenuOverlay: React.ElementType<any, any>;
}

const getDropdownParent = (el: HTMLElement | null): HTMLElement | null => {
  if (el === null || !(el instanceof HTMLElement)) {
    return null;
  }

  if (el.parentElement?.classList.contains(styles.dropdown)) {
    return el.parentElement;
  }
  return getDropdownParent(el.parentElement);
};

const activeDropdowns: { id: string; close: () => void; parentId?: null | string }[] = [];

const Dropdown = forwardRef(
  <C extends React.ElementType>(
    {
      'data-testid': dataTestid,
      arrow,
      children,
      element,
      elementProps,
      ignoreOutsideClick,
      isDisabled,
      isHoverMode,
      onClick,
      outsideClick = 'click',
      overlay,
      overlayClassName,
      overlayLeft,
      overlayTop,
      position = 'bottom-left',
      stopPropagation,
      style,
      title,
      triggerClassName,
      wrapperClassName,
      onToggle,
      onClose,
      overlayMaxWidth,
      mode = 'automatic',
      keepOpenOnActionElementClick,
    }: DropdownProps<C>,
    ref,
  ) => {
    const [active, setActive] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<number>();
    const Component = element || 'button';
    const dropdownId = useRef(uuidv4());

    const closeDropdown = useCallback(() => {
      if (onToggle) onToggle(false);
      setActive(false);
      onClose?.();
    }, [onClose, onToggle]);

    const openDropdown = useCallback(() => {
      setActive(true);
      if (onToggle) onToggle(true);
    }, [onToggle]);

    useImperativeHandle(ref, () => {
      const current = dropdownRef.current;
      return {
        openDropdown,
        closeDropdown,
        isActive: active,
        ...(current ? { ...current } : {}),
      };
    }, [openDropdown, closeDropdown, active]);

    const handlePointerEnter = () => {
      window.clearTimeout(timeoutRef.current);
      openDropdown();
    };

    const handleTriggerLeave = () => {
      timeoutRef.current = window.setTimeout(() => closeDropdown(), 700);
    };

    const toggleDropdown = (event) => {
      if (mode === 'manual' || (keepOpenOnActionElementClick && active)) return;

      if (isHoverMode || !active) {
        openDropdown();
      }
      if (active) {
        closeDropdown();
      }

      onClick?.();
      event.stopPropagation();
      event.preventDefault();
    };

    const handleOutsideClick = (event: PointerEvent) => {
      const isOutsideClick = !dropdownRef.current?.contains?.(event.target as Node);

      if (isOutsideClick && active && !ignoreOutsideClick) {
        closeDropdown();
      }
    };

    const handleAllDropdowns = useCallback(() => {
      if (!activeDropdowns.some(({ id }) => id === dropdownId.current)) {
        const parentDropdown = getDropdownParent(dropdownRef.current);
        if (activeDropdowns.length) {
          // In case we have this dropdown is within another dropdown
          // we close only the dropdowns that are on the same level within the same parent
          // otherwise we close all of them
          if (parentDropdown) {
            activeDropdowns
              .filter(({ parentId }) => parentId === parentDropdown.getAttribute('data-dropdownid'))
              .forEach(({ close }) => {
                close();
              });
          } else {
            activeDropdowns.forEach(({ close }) => close());
            activeDropdowns.length = 0;
          }
        }
        activeDropdowns.push({
          parentId: parentDropdown?.getAttribute('data-dropdownid'),
          id: dropdownId.current,
          close: closeDropdown,
        });
      }
    }, [closeDropdown]);

    const cleanup = useCallback(() => {
      const index = activeDropdowns.findIndex(({ id }) => id === dropdownId.current);

      if (index >= 0) {
        activeDropdowns.splice(
          activeDropdowns.findIndex(({ id }) => id === dropdownId.current),
          1,
        );
      }
    }, []);

    useOnEscape(closeDropdown);
    useEventListener(outsideClick, handleOutsideClick);

    useEffect(() => {
      if (active && dropdownRef.current) {
        handleAllDropdowns();
      } else {
        cleanup();
      }
      return () => {
        cleanup();
      };
    }, [active, handleAllDropdowns, cleanup]);

    const wrapperStyles = cx(styles.dropdown, wrapperClassName, { [styles.dropdownDisabled]: isDisabled });

    return (
      <div
        data-dropdownid={dropdownId.current}
        className={wrapperStyles}
        ref={dropdownRef}
        {...(stopPropagation && { onPointerDown: (event) => event.stopPropagation() })}
        {...(stopPropagation && { onPointerUp: (event) => event.stopPropagation() })}
        {...(isHoverMode && { onPointerEnter: handlePointerEnter })}
        {...(isHoverMode && { onPointerLeave: handleTriggerLeave })}
      >
        <Component
          aria-expanded={active}
          aria-label={title || 'Dropdown toggle'}
          className={cx(styles.dropdownTrigger, triggerClassName)}
          data-testid={dataTestid}
          onClick={toggleDropdown}
          type="button"
          title={title}
          {...(elementProps && { ...elementProps, active })}
        >
          {children}
        </Component>

        {active && (
          <DropdownOverlay
            arrow={arrow}
            overlayClassName={overlayClassName}
            closeDropdown={closeDropdown}
            isHoverMode={isHoverMode}
            style={style}
            overlay={overlay}
            overlayMaxWidth={overlayMaxWidth}
            overlayTop={overlayTop}
            position={position}
            overlayLeft={overlayLeft}
            handlePointerEnter={handlePointerEnter}
          />
        )}
      </div>
    );
  },
) as DropdownComponent;

Dropdown.displayName = 'Dropdown';
Dropdown.MenuOverlay = MenuOverlay;

export default Dropdown;
