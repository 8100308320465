import { QRCodeSVG } from 'qrcode.react';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { Button, Input, ShakeDiv } from 'frontend/components';
import { useUrlSearchParams } from 'frontend/hooks';
import PublicLayout from 'frontend/layouts/PublicLayout/PublicLayout';
import { isAuthenticated, selectAuth, selectError, selectStep, skipOtp, verifyOtp } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

import HardRedirect from './HardRedirect';
import styles from './Login.scss';
import RouterRedirect from './RouterRedirect';

const authViewPath = '/accounts/help_center_auth/';

const OTP = () => {
  const dispatch = useAppDispatch();
  const authenticated = useSelector(isAuthenticated);
  const loginError = useSelector(selectError);
  const { otpVoluntarySetup, otpAuthUrl, otpBase32 } = useSelector(selectAuth);
  const step = useSelector(selectStep);

  const onSubmit = useCallback(({ otp }) => dispatch(verifyOtp(otp)), [dispatch]);

  const [{ next }] = useUrlSearchParams();
  const nextIsServerSide: boolean = next?.startsWith(authViewPath);

  if (authenticated && nextIsServerSide) return <HardRedirect next={next} />;
  if (authenticated) return <RouterRedirect next={next} />;

  const redirectPath = `/login${next ? `?next=${encodeURIComponent(next)}` : ''}`;
  if (step === 'login') return <RouterRedirect next={redirectPath} />;

  return (
    <PublicLayout>
      <div className={styles.login}>
        <h1 className={styles.loginHeader}>Login</h1>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              {otpAuthUrl && otpBase32 ? (
                <>
                  <p className={styles.loginInfoText}>
                    One of your organization is requiring log in with two factor authentication, scan the following QR
                    code using your phone&lsquo;s authenticator application or enter the setup key and provide the
                    generated OTP code.
                  </p>
                  <div className={styles.qrcode}>
                    <QRCodeSVG value={otpAuthUrl} size={256} level="M" title="OTP QR Code" />
                    <span>
                      Or use the following key: <pre>{otpBase32}</pre>
                    </span>
                  </div>
                  <Field
                    component={Input}
                    className="m-b-2"
                    name="otp"
                    placeholder="Authentication code"
                    inputType="number"
                    label="Authentication code"
                    autoFocus
                    autoComplete="false"
                  />
                </>
              ) : (
                <>
                  <Field
                    component={Input}
                    className="m-b-2"
                    name="otp"
                    placeholder="Authentication code"
                    inputType="number"
                    label="Authentication code"
                    autoFocus
                    autoComplete="false"
                  />
                  <p className={styles.loginInfoText}>
                    Open your two-factor authenticator app (e.g., Microsoft Authenticator, Google Authenticator) to view
                    your authentication code.
                  </p>
                </>
              )}
              <div className={styles.rightAlignedButtonContainer}>
                {otpVoluntarySetup && (
                  <Button type="button" color="secondary" onClick={() => dispatch(skipOtp())}>
                    Skip
                  </Button>
                )}
                <Button type="submit" color="primary">
                  Login
                </Button>
              </div>
              <ShakeDiv animate={loginError ? 'error' : 'normal'} className="m-t-4 m-b-4">
                {loginError && <div className={styles.loginError}>{loginError?.message}</div>}
              </ShakeDiv>
            </form>
          )}
        />
      </div>
    </PublicLayout>
  );
};

export default OTP;
