import { Navbar } from 'frontend/features';
import Comments from 'frontend/features/Comments/Comments';
import { shouldShowComments } from 'frontend/features/Comments/utils/helpers';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './AuthenticatedLayout.scss';
import { useFullscreenLayout, useTabNotifications } from './hooks';

interface AuthenticatedLayoutProps {
  children: React.ReactNode;
}

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
  const fullScreenLayout = useFullscreenLayout();
  const { isKindlyAdminManager } = useMyPermissions();

  useTabNotifications();

  if (fullScreenLayout) {
    return <div>{children}</div>;
  }

  const showComments = shouldShowComments(window.location.pathname);

  return (
    <main>
      <Navbar />
      <div className={styles.mainWrapper}>
        {children}
        {showComments && isKindlyAdminManager && <Comments />}
      </div>
    </main>
  );
};

export default AuthenticatedLayout;
