import { type ApolloClient, useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DeleteFolderDocument, IsDialogueInFolderDocument } from 'frontend/api/generated';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';
import { getCurrentFormId } from 'frontend/utils';

async function isRedirectNecessary({
  client,
  buildIdObject,
  deletedTopicId,
  pathname,
}: {
  client: ApolloClient<unknown>;
  buildIdObject: BuildIdObject;
  deletedTopicId: string;
  pathname: string;
}): Promise<boolean> {
  const currentDialogueId = getCurrentFormId('dialogue', pathname);
  if (currentDialogueId && currentDialogueId !== 'new') {
    try {
      const { data } = await client.query({
        query: IsDialogueInFolderDocument,
        variables: {
          ...buildIdObject,
          folderId: deletedTopicId,
          dialogueId: currentDialogueId,
        },
      });
      return Boolean(data.dialogue?.isInFolder);
    } catch (e) {
      // If current dialogue is a skill subscription dialogue it will not be found,
      // but this also means it is not followup to a deleted topic
      return false;
    }
  }
  return false;
}

export function useDeleteFolder({
  folder,
  botOrSkillParams,
}: {
  folder: { name: string; id: string; parentTopicId?: string | null };
  botOrSkillParams: {
    buildIdObject: BuildIdObject;
    buildType: string;
    buildId: string;
  };
}) {
  const [deleteMutation] = useMutation(DeleteFolderDocument, REFETCH_LIBRARY);
  const client = useApolloClient();
  const toast = useToast();
  const { buildIdObject, buildType, buildId } = botOrSkillParams;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const deleteFolderFunction = useCallback(async () => {
    const { id } = folder;
    const redirect = await isRedirectNecessary({ client, buildIdObject, deletedTopicId: id, pathname });
    await deleteMutation({ variables: { folderId: id, ...buildIdObject } });

    if (redirect) {
      navigate(`/${buildType}/${buildId}/build`);
    }
    toast.success(`Folder '${folder.name}' was deleted!`);
  }, [buildId, buildIdObject, buildType, client, deleteMutation, pathname, navigate, toast, folder]);

  return deleteFolderFunction;
}
