import { mapKeys, snakeCase } from 'lodash';
import { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { Button, Checkbox, Input } from 'frontend/components';
import { chain, email, equals, min, required } from 'frontend/form/validators';
import { loginWithUsernameAndPassword } from 'frontend/state/dux/auth';
import { useAppDispatch } from 'frontend/state/hooks';

import styles from '../../Invitation.scss';
import type { Invitation } from '../../propTypes';
import ContentBox from '../ContentBox';
import Info from '../Info';

const snakeCaseKeys = (object) => mapKeys(object, (_, key) => snakeCase(key));

interface Props {
  invitation: Invitation;
  accept: (params: { body: string; onError: (response: Response) => Promise<void> }) => Promise<void>;
}

export default function CreateUserForm({ invitation, accept }: Props) {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const initialValues = useMemo(() => ({ username: invitation.email }), [invitation.email]);

  const createUser = async (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const body = JSON.stringify(snakeCaseKeys(values));

      const onError = async (response) => {
        switch (response.status) {
          case 400: {
            const { errors } = await response.json();
            return setError(errors);
          }
          case 403:
            return setError(['User does not match invitation']);
          case 409:
            return setError(['User already exists']);
          default:
            return setError(['Unknown error']);
        }
      };

      await accept({ body, onError });
      await dispatch(loginWithUsernameAndPassword(values.username, values.password1));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ContentBox>
      <>
        <Info invitation={invitation} />
        <p className={styles.description}>Accept the invitation by creating a user:</p>
        <Form
          onSubmit={createUser}
          initialValues={initialValues}
          validate={(values) => {
            const errors: Record<string, unknown> = {};
            if (values.privacyTerms !== true) {
              errors.privacyTerms = "You must accept Kindly's privacy policy in order to create an account.";
            }
            return errors;
          }}
          render={({ handleSubmit, values: { password1 } }) => (
            <form onSubmit={handleSubmit} className={styles.createUser} autoComplete="off">
              <Field
                component={Input}
                className="m-b-sm"
                name="firstName"
                placeholder="First name"
                type="text"
                validate={required}
                label="First name"
                autoFocus
              />
              <Field
                component={Input}
                className="m-b-sm"
                name="lastName"
                placeholder="Last name"
                label="Last name"
                type="text"
                validate={required}
              />
              <Field
                component={Input}
                className="m-b-sm"
                name="username"
                placeholder="Email"
                label="Email"
                inputType="email"
                readOnly
                validate={chain([email, required])}
              />
              <Field
                component={Input}
                className="m-b-sm"
                name="password1"
                placeholder="Password"
                label="Password"
                inputType="password"
                validate={chain([required, min(12)])}
              />
              <Field
                component={Input}
                className="m-b-md"
                name="password2"
                placeholder="Repeat password"
                label="Repeat password"
                inputType="password"
                validate={chain([required, equals('Password', password1)])}
              />

              <div className={styles.privacy}>
                <Field name="privacyTerms" type="checkbox">
                  {({ input, meta }) => (
                    <>
                      <Checkbox
                        input={input}
                        className={styles.privacyCheckbox}
                        id="privacyTerms-1692620864687"
                        label={
                          <label className={styles.privacyText} htmlFor="privacyTerms-1692620864687">
                            I accept{' '}
                            <a
                              href="https://www.kindly.ai/legal/privacy-policy-customers"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Kindly&apos;s privacy policy
                            </a>
                          </label>
                        }
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <span className={styles.privacyError}>{meta.error || meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className={styles.loginError}>{error.length > 0 ? error[0] : error}</div>
              <div className={styles.buttonContainer}>
                <Button color="primary" type="submit" isSubmitting={isSubmitting} text="Create new user" />
              </div>
              <div className={styles.termsOfUse}>
                By accessing the platform you agree to the same{' '}
                <a href="https://www.kindly.ai/legal/terms-of-service" target="_blank" rel="noopener noreferrer">
                  General Terms of Service
                </a>{' '}
                that {invitation.organization.name} (“Customer“) has agreed on.
              </div>
            </form>
          )}
        />
      </>
    </ContentBox>
  );
}
