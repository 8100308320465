import React, { useCallback, useContext, useMemo } from 'react';

import { Checkbox, LoaderSwitch } from 'frontend/components';
import { FilterContext } from 'frontend/components/NewFilters/context/FilterContext';
import { useBotOrSkill, useLanguages } from 'frontend/hooks';

const Languages = ({
  filterKey,
  step = 0,
  selectMultiple,
}: {
  filterKey: string;
  step?: number;
  selectMultiple?: boolean;
}) => {
  const botOrSkillParams = useBotOrSkill();
  const { languages, loading: languagesLoading } = useLanguages(botOrSkillParams);

  const { steps, setSteps } = useContext(FilterContext);

  const currentValues = useMemo(() => steps?.[filterKey]?.values?.[step] || [], [step, filterKey, steps]);

  const handleCheckboxClick = useCallback(
    (code: string) => {
      const newStepValues = currentValues.includes(code)
        ? currentValues.filter((v) => v !== code)
        : [...currentValues, code];

      const newValues = [...(steps?.[filterKey]?.values || [])];
      newValues[step] = newStepValues;

      setSteps({
        ...steps,
        [filterKey]: {
          currentStep: selectMultiple ? step : step + 1,
          values: newValues,
        },
      });
    },
    [currentValues, step, filterKey, steps, setSteps, selectMultiple],
  );

  return (
    <LoaderSwitch loading={languagesLoading}>
      {languages.map(({ code, name }) => (
        <Checkbox
          key={code}
          input={{
            name: code,
            checked: (steps?.[filterKey]?.values?.[step] || []).includes(code),
          }}
          allowToggleOnWrapper
          onClick={() => handleCheckboxClick(code)}
          label={name}
        />
      ))}
    </LoaderSwitch>
  );
};

export default Languages;
