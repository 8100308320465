import type { ApolloClient } from '@apollo/client';

import { LibraryDialoguesDocument, LibraryFoldersDocument } from 'frontend/api/generated';
import type { DropDialogueItem, DropFolderItem } from 'frontend/features/Library/components/FolderItem/utils';
import { getDialogueParentVariables } from 'frontend/features/Library/utils';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';
import { fetchIfNecessary } from 'frontend/utils';

export function updateSkillsubscriptionDialogues({
  buildIdObject,
  client,
  dialogue,
  skillId,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  dialogue: DropDialogueItem | DropFolderItem | undefined;
  skillId: string;
}) {
  fetchIfNecessary({
    client,
    query: LibraryDialoguesDocument,
    variables: {
      ...(dialogue ? getDialogueParentVariables(buildIdObject, dialogue) : undefined),
      skillId,
    },
  });
}

export function updateSkillsubscriptionTopics({
  buildIdObject,
  client,
  parentTopicId,
  skillId,
}: {
  buildIdObject: BuildIdObject;
  client: ApolloClient<unknown>;
  parentTopicId: string;
  skillId: string;
}) {
  fetchIfNecessary({ client, query: LibraryFoldersDocument, variables: { skillId, ...buildIdObject, parentTopicId } });
}
